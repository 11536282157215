import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes';
import moment from 'moment';

import {
  LAST_VISITED_VOUCHER,
  UPDATE_VOUCHERS,
  VOUCHER_DURATION_TYPE,
  UPDATE_VOUCHER_TYPES,
  VOUCHER_TYPE_SALES,
  EDIT,
  VOUCHER_TYPE_RECEIPT,
  VOUCHER_TYPE_PAYMENT,
  VOUCHER_TYPE_PURCHASE,
  VOUCHER_TYPE_EXPENSE,
  VOUCHER_TYPE_CREDIT_NOTE,
  VOUCHER_TYPE_DEBIT_NOTE,
  VOUCHER_TYPE_JOURNAL,
  VOUCHER_TYPE_CHALLAN,
  VOUCHER_TYPE_SCHALLAN,
  VOUCHER_TYPE_LINKED_SCHALLAN,
  VOUCHER_TYPE_LINKED_PCHALLAN,
  VOUCHER_TYPE_LINKED_SESTIMATION,
  VOUCHER_TYPE_LINKED_PESTIMATION,
  VOUCHER_TYPE_CONTRA,
  VOUCHER_TYPE_PCHALLAN,
  VOUCHER_TYPE_SESTIMATION,
  VOUCHER_TYPE_PESTIMATION,
  GROUPWISE_DISCOUNT_MODAL,
  EDIT_EXCLUSIVE_DISCOUNT_MODAL,
  RUPEES,
  PERCENT,
  CREATE,
  VOUCHER_TYPE_STOCK_TRANSFER,
  VOUCHER_TYPE_STOCK_ADJUSTMENT,
  CATALOGUE_FILTERS_KEY,
  INDIA,
  ITEM_VARIANT_FILTER_KEY,
  SALES,
  ADJUSTMENT_ON_ITEM_PRICE
} from '../constants';

import {
  ON_CLOSE_VOUCHER_PICKER,
  UPDATE_PARTY_NAME,
  RESET_VOUCHER_LINE_ITEMS,
  FETCH_VOUCHER_SUCCESSED,
  FETCH_UNPAID_VOUCHERS_SUCCESS,
  FETCH_UNPAID_VOUCHERS_FAILURE,
  ADD_LINE_ITEM_CRDR,
  RESET_SHIPPING_DETAILS,
  HANDLE_VOUCHER_DETAILS_DONE,
  RESET_TRANSPORT_DETAILS,
  RESET_VOUCHER_DETAILS,
  FETCH_ACCOUNTS_SUCCESS,
  VOUCHERS_LIST_REQUESTED,
  UPDATE_SELECTED_VOUCHER,
  FETCH_VOUCHER_BY_NUMBER_SUCCESS,
  ADD_OTHER_CHARGES_NEW_LINE,
  ADD_OTHER_CHARGES_AFTER_TAX_NEW_LINE,
  VOUCHERS_LIST_SUCCESS,
  SAVED_VOUCHER_SUCCESS,
  ON_HIDE_OTHER_CHARGES,
  ON_HIDE_OTHER_CHARGES_AFTER_TAX,
  GET_VOUCHER_REQUESTED,
  GET_VOUCHER_SUCCESS,
  VOUCHER_DATE_RANGE,
  UPDATE_BUSINESS_CONTACT,
  CLOSE_BUSINESS_CARD,
  UPDATE_VOUCHER_NO,
  UPDATE_DATE,
  TOGGLE_PURCHASE_SALES_DRAWER,
  VIEW_TAX_ANALYSIS,
  HANDLE_TRANSPORT_DONE,
  FETCH_ALL_BRANCHES_SUCCESS,
  FETCH_ALL_AJ_TALLY_BRANCHES_SUCCESS,
  FETCH_ALL_AJ_TALLY_BRANCHES_REQUESTED,
  FETCH_ALL_AJ_TALLY_BRANCHES_FAILED,
  UPDATE_SHIPPING_ADDRESS,
  UPDATE_PIN_CODE,
  UPDATE_CITY,
  UPDATE_STATE,
  SHOW_LINE_ITEM_PICKER,
  SET_CURRENT_ITEM_TO_FOCUS,
  UPDATE_LINE_ITEM,
  ADD_LINE_ITEM,
  ADD_LINE,
  UPDATE_ACCOUNT_FIELD,
  UPDATE_FILTERED_NAMES,
  ACCOUNTS_NAME,
  DRAWER_OPEN_ADD_ACCOUNTS,
  ON_FOCUS_CURRENT_INDEX,
  UPDATE_CHARGES,
  SET_ROUND_OFF_VALUE,
  DELETE_CHRAGES,
  DELETE_OTHER_CHRAGES_AFTER_TAX,
  UPDATE_TRANSPORT_DETAILS,
  SET_DISCOUNT_UNITS,
  SET_DISCOUNT_VALUE,
  RESET_ADD_DISCOUNT,
  TOGGLE_OTHER_CHARGES,
  CURRENT_CHARGE_INDEX_FOCUS,
  UPDATE_ACCOUNT,
  CURRENT_OTHER_CHARGES_AFTER_TAX_INDEX_FOCUS,
  POPULATE_ACCOUNTS_SUGGESTION,
  UPDATE_AMOUNT,
  DELETE_CHARGES,
  UPDATE_LINE_ITEM_FIELD,
  DISCOUNT_CHANGE,
  DELETE_LINE_ITEM,
  UPDATE_VOUCHERS_DETAILS_ACCOUNTS,
  UPDATE_NARRATION,
  LINE_ITEM_INDEX,
  UPDATE_TAX_BRACKUP,
  SET_SHIPPING_ADDRESS,
  SET_VOUCHER_TYPE,
  BRANCH_SEARCH_TEXT,
  UPDATE_SHIPPING_FOR_SELECTED_VOUCHER,
  TOGGLE_SHIPPING_DETAILS_LINK,
  ON_HIDE_SHOW_LINK,
  POPULATE_ADDTIONAL_DISCOUNT,
  POPULATE_OTHER_CHARGES,
  SET_SELECTED_VOUCHER_ID,
  RESET_LINE_ITEMS_DETAILS,
  FETCH_VOUCHERS_DATA,
  HIDE_SHIPPING_DETAILS,
  RESET_VOUCHER_FORM,
  TOGGLE_VOUCHER_DETAILS_LINK,
  TOGGLE_ALERT_DIALOG,
  UPDATE_TRANSPORT_FOR_SELECTED_VOUCHER,
  POPULATE_OTHER_CHARGES_AFTER_TAX,
  POPULATE_ROUNDOFF_VALUE,
  SET_LINE_ITEM_SEARCH_TEXT,
  TOGGLE_TRANSPORT_LINK,
  SET_CHALLAN_LIST_TO_BE_CREATED_AS_SPVOUCHER,
  FETCH_BANK_LIST_SUCCESS,
  FETCH_PAYMENT_METHOD_SUCCESS,
  COMPANY_ADD_ACCOUNT_SUCCESS,
  TOGGLE_ADD_EDIT_DRAWER,
  VOUCHER_BRANCH_POPULATE,
  VOUCHERS_DETAILS_ACCOUNTS_POPULATE,
  SET_SELECTED_VOUCHER_NO,
  ACTION_SAVE_BUTTON_DISABLE,
  SET_SUPPLIER_INVOICE_NO,
  SET_SUPPLIER_INVOICE_DATE,
  SET_SELECTED_VOUCHER_BY_REF_NO,
  SET_VOUCHER_UPLOADED_DOCUMENTS,
  ADD_EXPENSE_LINE,
  SET_ROUND_OFF_OFFSET,
  ON_TOGGLE_EXCLUSIVE_DISCOUNT,
  TOGGLE_GROUPWISE_DISCOUNT_MODAL,
  RESET_EXCLUSIVE_DISCOUNT,
  FETCH_VOUCHER_TYPES_SUCCESS,
  FETCH_VOUCHER_TYPES_FAILED,
  SET_ESTIMATION_LINKED_ITEM_LIST,
  FETCH_SHARE_VOUCHER_DATA_SUCCESS,
  FETCH_SHARE_VOUCHER_DATA_FAILED,
  FETCH_VOUCHER_ITEMS_SUCCESS,
  FETCH_VOUCHER_ITEMS_FAILED,
  FETCH_VOUCHERS_DATA_REQUEST,
  FETCH_VOUCHERS_DATA_SUCCESS,
  FETCH_VOUCHERS_DATA_FAILED,
  UPDATE_LAST_LINE_ITEM_PRICE,
  RESET_VOUCHER_ITEMS,
  POPULATE_COUPON_DISCOUNT,
  FETCH_ACCOUNTS_REQUESTED,
  COMPANY_ADD_ACCOUNT_FIELD_METADATA,
  ADD_EXPENSE_LINE_ITEM,
  TOGGLE_EDIT_EXCLUSIVE_DISCOUNT_MODAL,
  FETCH_UNPAID_VOUCHERS_REQUESTED,
  UPDATE_SHIPPING_GEO_LOC,
  UPDATE_EXCLUSIVE_DISCOUNT_DATA,
  GET_CALCULATED_ITEMS,
  GET_AUTO_POPULATE_ROUNDOFF_DATA,
  GET_LINE_AMOUNT_SUM,
  GET_BILL_TAX_AMOUNT,
  GET_BILL_ITEMS_PRICE,
  GET_BILL_DISCOUNT_AMOUNT,
  GET_BILL_AMT_AND_DUE_AMT,
  GET_OTHER_CHARGES_TOTAL,
  GET_OTHER_CHARGES_AFTER_TAX_TOTAL,
  GET_ADDITIONAL_DISCOUNT_CURRENCY_AMOUNT,
  GET_TAX,
  GET_TAX_ANALYSIS,
  GET_EXCLUSIVE_DISCOUNT_LIST_AND_TOTAL,
  POPULATE_BILL_FINAL_AMOUNT,
  POPULATE_BILL_TAX_AMOUNT,
  SET_CALCULATION_STATUS,
  VOUCHER_CALC_TOGGLE,
  POPULATE_BILL_TAX,
  STORE_OLD_LINE_ITEM,
  GET_CALCULATED_ITEM,
  REFRESH_LINE_ITEM,
  CURRENT_VOUCHER_LIST_SUCCESS,
  RESET_OLD_LINE_ITEM,
  POPULATE_BILL_DISCOUNT_AMOUNT,
  UPDATE_GROUP_WISE_ITEM_COUNT,
  POPULATE_LINE_AMOUNT_SUM,
  POPULATE_BILL_ITEMS_PRICE,
  POPULATE_OTHER_CHARGES_TOTAL,
  POPULATE_OTHER_CHARGES_AFTER_TAX_TOTAL,
  VOUCHER_LIST_LOADING,
  SELECT_PREPAREDBY_FILTER,
  SELECT_PARTY_FILTER,
  SELECT_STATUS_FILTER,
  SELECT_DUE_AMOUNT_FILTER,
  SELECT_BRANCH_FILTER,
  RESET_FILTERS,
  HIDDEN_VOUCHER_LIST_SUCCESS,
  SELECT_READ_LOCK_FILTER,
  SELECT_UPDATE_LOCK_FILTER,
  SAVE_PARENT_VOUCHER_OTHERCHARGES,
  SAVE_CHILD_VOUCHER_OTHERCHARGES_DIFF,
  RESET_PARENT_CHILD_VOUCHER_DATA,
  POPULATE_ADDITIONAL_DISCOUNT_AMOUNT,
  SET_SELECTED_BRANCH,
  TOGGLE_CHILD_ERROR_FLAG,
  FETCH_PARTY_SALES_DISCOUNT_SUCCESS,
  SET_VOUCHER_ITEM_LIST,
  CLEAR_UNPAID_VOUCHERS,
  VOUCHER_DATE,
  ADD_RP_ACCOUNT,
  FETCH_VOUCHER_ITEMS_REQUESTED,
  FETCH_SHARE_VOUCHER_DATA_REQUESTED,
  SET_DRAWER_MODE,
  FETCH_ALL_BRANCHES_REQUESTED,
  FETCH_ALL_BRANCHES_FAILED,
  FETCH_ACCOUNTS_FAILED,
  SHARE_VOUCHER_REQUESTED,
  SHARE_VOUCHER_SUCCESS,
  SHARE_VOUCHER_FAILED,
  UPDATED_VOUCHER_SUCCESS,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILED,
  SET_INWARD_OUTWARD_SUBTYPE,
  SET_SELECTED_DESTINATION_BRANCH,
  FETCHED_VOUCHER_TYPE,
  GET_CURRENT_BALANCE_REQUESTED,
  GET_CURRENT_BALANCE_SUCCESS,
  GET_CURRENT_BALANCE_FAILED,
  HIDDEN_VOUCHER_LIST_REQUESTED,
  HIDDEN_VOUCHER_LIST_ERROR,
  SELECT_ACCOUNT_FILTER,
  FETCH_SHARE_VOUCHER_PUBLIC_DATA_REQUESTED,
  FETCH_SHARE_VOUCHER_PUBLIC_DATA_SUCCESS,
  FETCH_SHARE_VOUCHER_PUBLIC_DATA_FAILED,
  TOGGLE_RECORD_RECEIPT_MODAL,
  TOGGLE_SALES_PERSON_LINK,
  UPDATE_SALES_PERSON_DETAILS,
  FETCH_ITEM_DETAILS_REQUESTED,
  FETCH_ITEM_DETAILS_SUCCESS,
  FETCH_ITEM_DETAILS_FAILED,
  DELETE_VOUCHER_REQUEST,
  DELETE_VOUCHER_SUCCESS,
  DELETE_VOUCHER_FAILED,
  POPULATE_DUE_AMOUNT,
  FETCH_LINE_ITEM_AVALIABLE_QTY_SUCCESS,
  FETCH_LINE_ITEM_AVALIABLE_QTY_FAILED,
  FETCH_LINE_ITEM_AVALIABLE_QTY_REQUESTED,
  FETCH_VOUCHER_DUE_AMOUNT_REQUESTED,
  FETCH_VOUCHER_DUE_AMOUNT_SUCCESS,
  FETCH_VOUCHER_DUE_AMOUNT_FAILED,
  POPULATE_ITEM_QTYS_TOTAL,
  UPDATE_GROUP_TO_CODE_MAP,
  UPDATE_LINE_ITEM_SR_NOS,
  SET_SUBTYPE_FILTER,
  SET_PARTY_SALES_DISCOUNT,
  TOGGLE_RECORD_RP_FROM_VOUCHER,
  SET_RECORD_RP_DATA_FROM_VOUCHER,
  TOGGLE_RECORD_RP_FROM_VOUCHER_MODAL,
  SET_DUE_DATE,
  UPDATE_COUNTRY,
  RESET_VOUCHER_DIALOG_FILTERS,
  RESET_ITEM_AVAILABLE_QTY_LIST,
  PRESERVE_VOUCHER_DATES,
  SET_VOUCHER_CREATION_MODE,
  POS_BILLING_PARTY,
  SET_NARRATION_FILTER,
  TOOGLE_PICKUP_LOCATION_LINK,
  UPDATE_PICKUP_LOCATION_DETAILS,
  RESET_SELECTED_VOUCHER,
  SET_SUB_TYPE,
  UPDATE_SHIPPING_ADDRESS_NAME,
  SET_EDIT_VOUCHER_ID,
  UPDATE_LINKED_ESTIMATION_STATUS,
  SET_COMPANY_VOUCHER_SETTINGS,
  FETCH_PARTY_PURCHASE_DISCOUNT_SUCCESS,
  SET_PARTY_PURCHASE_DISCOUNT
} from '../actions/types';

import omit from 'lodash/omit';
import {
  getdocumentSchema,
  getUnitPriceForPayload,
  getFormattedDate3,
  getfloatingValue,
  customSortForSrNoByGroup,
  customSortForSku,
  getFormattedDateIso,
  getTotalOfTax,
  setRefIdTotempJournals,
  getStartOfFinancialYear,
  getEndOfFinancialYear,
  getUnitDiscounteForPayload,
  salesTypeFlag,
  getFormattedDateWithTime,
  getUnitPriceFromMRP
} from '../utils/helperFunctions';
import find from 'lodash/find';
import { getVoucherViewMode } from './contacts';
import { calculateLineItemsDiscountBreakupAndEffectivePrice } from 'voucher-calculation/lib/optimize_voucher_pure_cal';

export const getLineItemSchema = (payload = {}, state, voucherSetting) => {
  const { itemDiscountValue, itemDiscountUnit } = getUnitDiscounteForPayload(state, payload);
  let lineItem = {
    qtySellPrice: payload.qtySellPrice || 0,
    hsn: payload.hsn ? payload.hsn : '7117',
    unit: payload.unit || '',
    // originalUnit: payload.unit,
    inStoreQty: payload.inStoreQty ? payload.inStoreQty : 0,
    itemId: payload.id || payload.itemId || '',
    discountUnit: payload.discountUnit || itemDiscountUnit || '%', //for replace discount unit taken from payload
    itemName: payload.name || payload.itemName || '',
    taxAmount: 0,
    discountAmount: 0,
    boxQty: payload.boxQty ? payload.boxQty : payload.qtyPerGroupedUnit ? 1 : 0,
    boxNo: payload.boxNo || 0,
    qtyPerBox: payload.qtyPerGroupedUnit || 1,
    qty: payload.qty ? payload.qty : 1,
    itemSkuBarCode: payload.skuBarcode || payload.itemSkuBarCode || '',
    unitPrice:
      payload.unitPrice ||
      getUnitPriceForPayload(state, payload, state.companyVoucherSettings) ||
      0, //for replace price taken from payload
    // originalUnitPrice: getUnitPriceForPayload(state, payload) || 0,
    description: payload.description || '',
    numericSkuBarcode: payload.numericSkuBarcode || null,
    // TODO Will need this later when this function works properly.
    // getUnitPriceAfterPartySpecialDiscount(
    //     payload.itemGroupId,
    //     payload.unitSellWholeSalePrice,
    //     payload.unitSellRetailPrice,
    //     salesDiscounts
    //   ),
    discountValue: payload.discountValue || itemDiscountValue || 0, //for replace discount value taken from payload
    discountIconRupees: false,
    unitPurchasePrice: payload.unitPurchasePrice || 0,
    unitSellWholeSalePrice: payload.unitSellWholeSalePrice || 0,
    unitSellRetailPrice: payload.unitSellRetailPrice || 0,
    // taxPercentage: payload.taxPercenatge ? payload.taxPercenatge : 3,   //changed as logic is failing for taxPercent 0
    taxPercentage: payload.taxPercentage || 0,
    // showItemPicker: false,
    itemGroupId: payload.itemGroupId || '',
    // itemGroupName: payload.itemGroup.name,
    partyDesignCode: payload.partyDesignCode || '',
    wholesaleMarkup: payload.wholesaleMarkup || 0,
    retailMarkup: payload.retailMarkup || 0,
    rateCode: payload.rateCode || '',
    lineAmount: payload.lineAmount || 0,
    qtyPerGroupedUnit: payload.qtyPerGroupedUnit,
    originalItemUnit: payload.unit,
    groupedUnit: payload.groupedUnit,
    isService: payload.isService || false,
    vendorId: payload.partyId && payload.partyId.length > 0 ? payload.partyId : null
  };

  if (
    voucherSetting?.columnVisibility?.mrp &&
    voucherSetting?.hiddenColumns?.indexOf('mrp') === -1
  ) {
    let price = getUnitPriceForPayload(state, payload, state.companyVoucherSettings) || 0;
    lineItem = {
      ...lineItem,
      mrp: price,
      unitPrice: getUnitPriceFromMRP(price, lineItem.taxPercentage, 2)
    };
  }

  if (
    (state?.type === VOUCHER_TYPE_CREDIT_NOTE || state?.type === VOUCHER_TYPE_DEBIT_NOTE) &&
    state?.subType === ADJUSTMENT_ON_ITEM_PRICE
  ) {
    lineItem.adjustedQty = payload.adjustedQty || 1;
    lineItem.qty = 0;
  }
  return lineItem;
};

const lastVisitedVoucher = (state = null, payload) => {
  if (payload.type === LAST_VISITED_VOUCHER) {
    return payload.payload;
  }
  return state;
};

const vouchersUpdateState = (state = false, action) => {
  if (action.type === UPDATE_VOUCHERS) {
    return action.payload;
  }
  // if (action.type === VOUCHERS_LIST_SUCCESS) {
  //   return !state;
  // }
  return state;
};

const getVoucherDurationType = (state = '', payload) => {
  if (payload.type === VOUCHER_DURATION_TYPE) {
    return payload.payload;
  }
  return state;
};

const voucherTypesUpdateState = (state = false, payload) => {
  if (payload.type === UPDATE_VOUCHER_TYPES) {
    return payload.payload;
  }
  return state;
};

const voucherListInitialState = {
  page: 1,
  hitsPerPage: 20,
  totalDataCount: 0,
  totalPages: 0,
  unpaidCount: 0,
  unpaidAmount: 0,
  paidCount: 0,
  paidAmount: 0,
  totalAmount: 0,
  data: []
};

const accountListJson = {
  name: '',
  description: '',
  creditAmount: 0,
  debitAmount: 0,
  refAccountId: '',
  accountGroupName: ''
};

const journalInitPayload = {
  accountList: [accountListJson, accountListJson],
  debitTotalAmount: 0,
  creditTotalAmount: 0,
  voucherNo: '',
  notifyParty: false,
  internalNotes: '',
  narration: ''
};

const itemAvaliableQtyInitial = {
  list: {},
  voucherQtys: {}
};

const voucherTypeInitialState = {
  openAddAccount: false,
  applyAddtionalDiscount: false,
  discountImg: false,
  party: {},
  type: '',
  _selectedVoucherId: '',
  _selectedVoucherNo: 'AutoGenerated',
  accountType: '',
  addressCheck: false,
  otherCharges: false,
  currentChargeIndex: 0,
  tempCharges: {},
  otherChargesDataSource: [],
  additionalDiscount: {
    value: 0,
    unit: '%'
  },
  addItemText: true,
  payload: {
    narration: '',
    adjustments: 0,
    otherCharges: 0,
    internalNotes: [],
    dueAmount: '0.00',
    notifyParty: false
  },
  autoPopulateRoundoffData: [],
  voucherItemsSuggestions: [],
  taxBreakup: [],
  totalTax: '0.00',
  currentItemIndex: 0,
  tempChargesAfterTax: {},
  currentAfterTaxIndex: 0,
  otherChargesAfterTax: false,
  otherChargesDataSourceAfterTax: [],
  charges: {
    discount: {
      value: 0,
      isPercent: true
    },
    additional: {}
  },
  charges_after_tax: {},
  verifiedBy: {},
  internalNotes: [],
  businessAccountsSuggestions: [],
  selectedBusinessAccount: null,
  roundoffValue: ''
};
const initialAddEditDrawerFlags = {
  mode: CREATE,
  [VOUCHER_TYPE_RECEIPT]: false,
  [VOUCHER_TYPE_PAYMENT]: false,
  [VOUCHER_TYPE_SALES]: false,
  [VOUCHER_TYPE_PURCHASE]: false,
  [VOUCHER_TYPE_EXPENSE]: false,
  [VOUCHER_TYPE_CREDIT_NOTE]: false,
  [VOUCHER_TYPE_DEBIT_NOTE]: false,
  [VOUCHER_TYPE_JOURNAL]: false,
  [VOUCHER_TYPE_CHALLAN]: false,
  [VOUCHER_TYPE_SCHALLAN]: false,
  [VOUCHER_TYPE_LINKED_SCHALLAN]: false,
  [VOUCHER_TYPE_LINKED_PCHALLAN]: false,
  [VOUCHER_TYPE_CONTRA]: false,
  [VOUCHER_TYPE_PCHALLAN]: false,
  [VOUCHER_TYPE_SESTIMATION]: false,
  [VOUCHER_TYPE_PESTIMATION]: false,
  [VOUCHER_TYPE_LINKED_SESTIMATION]: false,
  [VOUCHER_TYPE_LINKED_PESTIMATION]: false,
  [GROUPWISE_DISCOUNT_MODAL]: false,
  [EDIT_EXCLUSIVE_DISCOUNT_MODAL]: false
};

const finStartDate = sessionStorage.getItem('startDate');
const finEndDate = sessionStorage.getItem('endDate');

const initialState = {
  payloadId: moment().format('x'),
  type: '',
  savedVouchersSuccess: false,
  updatedVouchersSuccess: false,
  loadingVoucherList: false,
  voucherList: null,
  selectedVoucher: {},
  accountsName: [],
  challanListForSPVoucher: [],
  estimationLinkedItems: {},
  togglePurchaseSalesAddVoucher: false,
  setActionSaveButtonDisable: false,
  supplierInvoiceNo: '',
  couponDiscount: {
    value: 0,
    unit: '%'
  },
  parentVoucherInfo: {
    otherCharges: [],
    otherChargesAfterTax: []
  },
  childOtherChargesDiff: {
    otherCharges: [],
    otherChargesAfterTax: []
  },
  showChildUpdateError: false,
  filters: {
    selectedParty: '',
    selectedAccount: null,
    selectedPreparedBy: '',
    selectedStatus: 'both',
    includeDueAmount: false,
    selectedBranch: '',
    readLock: 'both',
    updateLock: 'both',
    subType: '',
    narration: ''
  },
  [CATALOGUE_FILTERS_KEY]: {
    selectedParty: ''
  },
  [ITEM_VARIANT_FILTER_KEY]: {
    selectedParty: ''
  },
  journalPayload: cloneDeep(journalInitPayload),
  hiddenVoucherList: {},
  hiddenVoucherListLoading: false,
  voucherListLoading: false,
  groupWiseItemCount: {},
  groupToCodeMap: {},
  supplierInvoiceDate: getFormattedDate3(),
  dueDate: getFormattedDateWithTime(),
  lastSavedVoucherDates: {},
  lastSavedVoucherPickupLocation: {},
  lastSavedVoucherTransport: {},
  documents: [],
  _issueDateObj: getFormattedDateWithTime(),
  _vouchersById: {
    purchase: {},
    sales: {},
    credit_note: {},
    debit_note: {},
    contra: {},
    journal: {},
    schallan: {},
    pchallan: {},
    sestimation: {},
    pestimation: {},
    expense: {},
    [VOUCHER_TYPE_STOCK_TRANSFER]: {},
    [VOUCHER_TYPE_STOCK_ADJUSTMENT]: {}
  },
  fetchedVoucherType: '',
  _vouchersList: {
    purchase: voucherListInitialState,
    sales: voucherListInitialState,
    credit_note: voucherListInitialState,
    debit_note: voucherListInitialState,
    contra: voucherListInitialState,
    journal: voucherListInitialState,
    schallan: voucherListInitialState,
    pchallan: voucherListInitialState,
    sestimation: voucherListInitialState,
    pestimation: voucherListInitialState,
    expense: voucherListInitialState,
    payment: voucherListInitialState,
    receipt: voucherListInitialState,
    [VOUCHER_TYPE_STOCK_TRANSFER]: voucherListInitialState,
    [VOUCHER_TYPE_STOCK_ADJUSTMENT]: voucherListInitialState
  },
  _branchSearchText: '',
  _currentBalance: {
    value: 0,
    loader: false,
    accessGranted: true
  },
  _unpaidVoucherList: {
    page: 1,
    totalDataCount: 0,
    data: {
      statement: []
    }
  },
  _allVoucherList: {
    page: 1,
    totalDataCount: 0,
    data: {
      statement: []
    }
  },
  againstVouchersLoading: false,
  _narration: '',
  purchase: voucherTypeInitialState,
  sales: voucherTypeInitialState,
  lineItemSearchText: null,
  _selectedVocuher: {},
  selectedPartyAccount: {
    purchaseDiscounts: []
  },
  _transport: {
    transporterId: '',
    name: '',
    date: getFormattedDate3(),
    ewayBillNo: '',
    ewayBillDate: getFormattedDate3(),
    deliveryNote: '',
    destination: '',
    dispatchDocNo: '',
    dispatchThrough: '',
    motorVehicleNo: '',
    transporterMode: 'Road'
  },
  _otherCharges: [],
  // _otherCharges: {
  //   0: {
  //     refAccountId: '',
  //     name: '',
  //     accountGroupName: '',
  //     description: '',
  //     creditAmount: 0,
  //     debitAmount: 0,
  //     amount: 0
  //   }
  // },
  date: null,
  shippingAddress: {
    city: '',
    state: '',
    address: '',
    pincode: 0,
    country: INDIA,
    name: ''
  },
  partyNameSearchText: '',
  _salesPerson: {},
  _selectedVoucherAccount: {},
  _shippingAddress: {
    city: '',
    state: '',
    address: '',
    pincode: 0,
    country: INDIA,
    name: '',
    id: ''
  },
  _searchedVoucherByNo: [],
  _selectedVoucherByNo: {},
  _voucherModuleResults: {
    lineAmountSum: 0,
    billTaxAmount: 0,
    billItemsPrice: 0,
    billFinalAmount: 0,
    billDiscountAmount: 0,
    autoPopulateRoundoffData: [],
    additionalDiscountCurrencyAmount: 0,
    exclusiveDiscountList: [],
    exclusiveDiscountTotal: 0,
    otherChargesTotal: 0,
    otherChargesAfterTaxTotal: 0
  },
  voucherAccountList: [],
  voucherAccounts: {},
  voucherAccountListLoading: false,
  _branches: [],
  _branchesLoading: false,
  _aj_tally_branches: [],
  _aj_tally_branchesLoading: false,
  _selected_aj_tally_branch: [],
  _selectedBranch: {},
  inwardOutwardSubtype: '',
  _selectedDestinationBranch: {},
  _voucherDetailsAccounts: '',
  _otherChargesAfterTax: [],
  // _otherChargesAfterTax: {
  //   0: {
  //     refAccountId: '',
  //     name: '',
  //     accountGroupName: '',
  //     description: '',
  //     creditAmount: 0,
  //     debitAmount: 0,
  //     amount: 0
  //   }
  // },
  _lineItems: [],
  oldLineItem: {},
  _roundOffValue: '',
  _roundOffOffset: 0,
  app: {
    toogleBusinessCard: false,
    showSalesPersonCard: false,
    salesPersonLink: true,
    showTransportCard: false,
    transportationLink: true,
    showVoucherDetailsCard: true,
    hideShippingDetails: false,
    showLink: true,
    voucherDetailsLink: true,
    showAlertFlag: false,
    alertMessage: '',
    lineItem: {
      currentItemIndex: 0,
      showItemPicker: false,
      lineItemSearchText: ''
    },
    summary: {
      viewTaxAnalysis: false
    },
    otherCharges: {
      onHideAddDiscount: false,
      onHideOtherCharges: false,
      currentChargeIndex: 0,
      currentChargesAfterTaxIndex: 0,
      otherChargesKey: 0,
      otherChargesAfterTaxKey: 0,
      onHideOtherChargesAfterTax: false
    },
    exclusiveDiscountFlag: false,
    // exclusiveDiscountList: [],
    // exclusiveDiscountTotal: 0,
    bankList: [],
    paymentMethods: [],
    calcEnabled: true,
    pickUpLocationLink: true
  },
  voucherTypes: [],
  shareData: {
    contacts: [],
    shareContacts: []
  },
  shareDataLoading: false,
  publicShareStatus: {
    loading: false,
    toContactDetail: {}
  },
  voucherItems: [],
  voucherItemsLoading: false,
  loadingVoucherData: {
    count: 0
  },
  addAccountField: {
    key: '',
    index: 0,
    account: {}
  },
  calcStatus: {
    [SET_CALCULATION_STATUS]: false
  },
  voucherLineItems: [],
  getDate: {
    startDate: finStartDate ? JSON.parse(finStartDate) : getStartOfFinancialYear(new Date()),
    endDate: finEndDate ? JSON.parse(finEndDate) : getEndOfFinancialYear(new Date())
  },
  shareVoucherRequested: false,
  recordReceiptMode: {
    flag: false,
    recordRPFlag: false,
    voucher: {},
    recordRpPayload: { amount: 0 }
  },
  itemDetailsRequested: false,
  voucherDueAmountRequested: false,
  posBilling: false,
  posBillingParty: {},
  _pickUpLocation: null,
  editVoucherId: null,
  linkedVoucherUpdate: {
    status: null,
    loading: false
  },
  companyVoucherSettings: {}
};

//state selectores
const getVoucherModuleResultState = state => {
  return state._voucherModuleResults;
};

//functions to update vouchers state

//abstract  functions
// const singleLevelStateUpdate = state => (key1, value1, key2, value2) => ({
//   ...state,
//   [key1]: value1,
//   [key2]: value2
// });
// const secondlevelStateUpdate = state => secondLevelKey => (key, value) => ({
//   ...state,
//   [secondLevelKey]: { ...state[secondLevelKey], [key]: value }
// });

//provide state
// const setFirstLevelWithInitalState = singleLevelStateUpdate(initialState);
// const setSecondLevelWithInitialState = secondlevelStateUpdate(initialState);

// purchase stateUpdate
// const setPurchase = setSecondLevelWithInitialState('purchase');
//
const addEditDrawerFlags = (state = initialAddEditDrawerFlags, action) => {
  switch (action.type) {
    case TOGGLE_ADD_EDIT_DRAWER:
      return {
        ...state,
        [action.key.toLowerCase()]: action.value
      };
    case SET_DRAWER_MODE:
      return {
        ...state,
        mode: action.mode
      };
    case TOGGLE_GROUPWISE_DISCOUNT_MODAL:
      return {
        ...state,
        [GROUPWISE_DISCOUNT_MODAL]: action.value
      };
    case TOGGLE_EDIT_EXCLUSIVE_DISCOUNT_MODAL:
      return {
        ...state,
        [EDIT_EXCLUSIVE_DISCOUNT_MODAL]: action.value
      };
    default:
      return state;
  }
};

const vouchers = (state = initialState, action) => {
  switch (action.type) {
    // This is basically for all combined Vouchers.
    // Parent voucher  = Sales, Purchase
    // Child voucher = Sestimation, Pestimation
    case 'UPDATE_PAYLOAD_ID':
      return {
        ...state,
        payloadId: moment().format('x')
      };
    case 'UPDATE_JOURNAL_PAYLOAD':
      return {
        ...state,
        journalPayload: {
          ...state.journalPayload,
          [action.key]: action.payload
        }
      };

    case 'ADD_JOURNAL_ACCOUNT':
      let tempAccList = cloneDeep(state.journalPayload.accountList);
      tempAccList[action.index] = {
        ...setRefIdTotempJournals('', action.account),
        creditAmount: 0,
        debitAmount: 0
      };
      return {
        ...state,
        journalPayload: {
          ...state.journalPayload,
          accountList: tempAccList
        }
      };

    case 'SET_JOURNAL_PAYLOAD':
      const voucher = action.payload;
      return {
        ...state,
        journalPayload: {
          accountList: voucher.accountList,
          voucherNo: voucher.voucherNo,
          notifyParty: voucher.notifyParty,
          internalNotes: voucher.internalNotes,
          debitTotalAmount: voucher.debitTotalAmount,
          creditTotalAmount: voucher.creditTotalAmount,
          narration: voucher.narration
        }
      };
    case 'RESET_JOURNAL_PAYLOAD':
      return {
        ...state,
        journalPayload: cloneDeep(journalInitPayload)
      };
    case TOGGLE_RECORD_RECEIPT_MODAL:
      return {
        ...state,
        recordReceiptMode: {
          ...state.recordReceiptMode,
          flag: action.flag,
          voucher: action.voucher
        }
      };
    case TOGGLE_RECORD_RP_FROM_VOUCHER_MODAL:
      return {
        ...state,
        recordReceiptMode: {
          ...state.recordReceiptMode,
          recordRpFromVoucherFlag: action.flag
        }
      };
    case TOGGLE_RECORD_RP_FROM_VOUCHER:
      return {
        ...state,
        recordReceiptMode: {
          ...state.recordReceiptMode,
          recordRPFlag: action.recordRPFlag
        }
      };
    case SET_RECORD_RP_DATA_FROM_VOUCHER:
      return {
        ...state,
        recordReceiptMode: {
          ...state.recordReceiptMode,
          recordRpPayload: action.recordRpPayload
        }
      };
    case RESET_PARENT_CHILD_VOUCHER_DATA:
      return {
        ...state,
        childOtherChargesDiff: initialState.childOtherChargesDiff,
        parentVoucherInfo: initialState.parentVoucherInfo
      };
    case SAVE_CHILD_VOUCHER_OTHERCHARGES_DIFF:
      return {
        ...state,
        childOtherChargesDiff: action.payload
      };
    case TOGGLE_CHILD_ERROR_FLAG:
      return {
        ...state,
        showChildUpdateError: action.payload
      };
    case SAVE_PARENT_VOUCHER_OTHERCHARGES:
      return {
        ...state,
        parentVoucherInfo: action.payload
      };
    case HIDDEN_VOUCHER_LIST_SUCCESS:
      return {
        ...state,
        hiddenVoucherList: action.payload,
        hiddenVoucherListLoading: false
      };
    case HIDDEN_VOUCHER_LIST_REQUESTED:
      return {
        ...state,
        hiddenVoucherListLoading: true
      };
    case HIDDEN_VOUCHER_LIST_ERROR:
      return {
        ...state,
        hiddenVoucherListLoading: false
      };
    case SELECT_PARTY_FILTER:
      return {
        ...state,
        [action.storeKey]: {
          ...state[action.storeKey],
          selectedParty: action.payload
        }
      };
    case SELECT_ACCOUNT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedAccount: action.payload
        }
      };
    case SELECT_READ_LOCK_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          readLock: action.payload
        }
      };
    case SELECT_UPDATE_LOCK_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          updateLock: action.payload
        }
      };
    case SET_SUBTYPE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          subType: action.payload
        }
      };
    case SELECT_PREPAREDBY_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedPreparedBy: action.payload
        }
      };
    case SELECT_BRANCH_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedBranch: action.payload
        }
      };
    case SELECT_STATUS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedStatus: action.payload
        }
      };
    case SELECT_DUE_AMOUNT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          includeDueAmount: action.payload
        }
      };
    case RESET_VOUCHER_DIALOG_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedAccount: null,
          selectedPreparedBy: '',
          includeDueAmount: false,
          selectedBranch: '',
          updateLock: 'both',
          readLock: 'both',
          narration: ''
        }
      };
    case RESET_FILTERS:
      return {
        ...state,
        filters: {
          selectedParty: '',
          selectedAccount: null,
          selectedPreparedBy: '',
          selectedStatus: 'both',
          includeDueAmount: false,
          selectedBranch: '',
          updateLock: 'both',
          readLock: 'both',
          narration: ''
        }
      };
    case ON_HIDE_OTHER_CHARGES:
      return {
        ...state,
        app: {
          ...state.app,
          otherCharges: {
            ...state.app.otherCharges,
            onHideOtherCharges: !state.app.onHideOtherCharges
          }
        }
      };
    case ON_TOGGLE_EXCLUSIVE_DISCOUNT:
      return {
        ...state,
        app: {
          ...state.app,
          exclusiveDiscountFlag: action.flag
          // exclusiveDiscountList: action.list,
          // exclusiveDiscountTotal: action.list.length && action.list[0].totalDiscountAmount
        }
      };

    case TOGGLE_SALES_PERSON_LINK:
      return {
        ...state,
        app: {
          ...state.app,
          salesPersonLink: action.flag
        }
      };
    case TOOGLE_PICKUP_LOCATION_LINK:
      return {
        ...state,
        app: {
          ...state.app,
          pickUpLocationLink: action.flag
        }
      };
    case VOUCHER_LIST_LOADING:
      return {
        ...state,
        voucherListLoading: action.payload
      };
    case RESET_EXCLUSIVE_DISCOUNT:
      return {
        ...state,
        app: {
          ...state.app,
          exclusiveDiscountFlag: action.flag
        },
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          exclusiveDiscountList: [],
          exclusiveDiscountTotal: 0
        }
      };
    case ACTION_SAVE_BUTTON_DISABLE:
      return {
        ...state,
        setActionSaveButtonDisable: action.payload
      };
    case SET_CHALLAN_LIST_TO_BE_CREATED_AS_SPVOUCHER:
      return {
        ...state,
        challanListForSPVoucher: action.payload
      };
    case FETCH_VOUCHERS_DATA:
      return {
        ...state,
        _selectedVocuher: action.selectedVoucher
      };
    case RESET_SELECTED_VOUCHER:
      return {
        ...state,
        _vouchersById: {
          ...state._vouchersById,
          [action.voucherType]: {}
        }
      };
    case FETCH_VOUCHERS_DATA_REQUEST:
    case DELETE_VOUCHER_REQUEST:
      return {
        ...state,
        loadingVoucherData: {
          count: state.loadingVoucherData.count + 1
        }
      };
    case FETCH_VOUCHERS_DATA_SUCCESS:
    case DELETE_VOUCHER_SUCCESS:
      return {
        ...state,
        loadingVoucherData: {
          count: state.loadingVoucherData.count - 1
        }
      };
    case FETCH_VOUCHERS_DATA_FAILED:
    case DELETE_VOUCHER_FAILED:
      return {
        ...state,
        loadingVoucherData: {
          count: state.loadingVoucherData.count - 1
        }
      };
    case SET_SELECTED_VOUCHER_BY_REF_NO:
      return {
        ...state,
        _selectedVoucherByNo: action.payload
      };
    case SET_VOUCHER_ITEM_LIST:
      return {
        ...state,
        voucherLineItems: action.response
      };
    case ON_HIDE_OTHER_CHARGES_AFTER_TAX:
      return {
        ...state,
        app: {
          ...state.app,
          otherCharges: {
            ...state.app.otherCharges,
            onHideOtherChargesAfterTax: !state.app.onHideOtherChargesAfterTax
          }
        }
      };
    case UPDATE_SALES_PERSON_DETAILS:
      return {
        ...state,
        _salesPerson: { ...action.payload },
        app: {
          ...state.app,
          salesPersonLink: false
        }
      };
    case UPDATE_PICKUP_LOCATION_DETAILS:
      return {
        ...state,
        _pickUpLocation: action.payload,
        lastSavedVoucherPickupLocation: action.payload,
        app: {
          ...state.app,
          pickUpLocationLink: false
        }
      };
    case UPDATE_TRANSPORT_DETAILS:
      return {
        ...state,
        _transport: {
          ...state._transport,
          ...action.details
        },
        lastSavedVoucherTransport: {
          ...state._transport,
          ...action.details
        }
      };
    case HANDLE_TRANSPORT_DONE:
      return {
        ...state,
        app: {
          ...state.app,
          showTransportCard: !state.app.showTransportCard
        }
      };
    case HANDLE_VOUCHER_DETAILS_DONE:
      return {
        ...state,
        app: {
          ...state.app,
          showVoucherDetailsCard: action.flag
        }
      };
    case ADD_OTHER_CHARGES_NEW_LINE:
      return {
        ...state,
        // _otherCharges: {
        //   ...state._otherCharges,
        //   [state.app.otherCharges.otherChargesKey + 1]: {
        //     ...state._otherCharges[state.app.otherCharges.otherChargesKey + 1],
        //     refAccountId: '',
        //     name: '',
        //     accountGroupName: '',
        //     description: '',
        //     creditAmount: 0,
        //     debitAmount: 0,
        //     amount: 0
        //   }
        // },
        // _otherCharges: [
        //   ...state._otherCharges,
        //   {
        //     refAccountId: '',
        //     name: '',
        //     accountGroupName: '',
        //     description: '',
        //     creditAmount: 0,
        //     debitAmount: 0,
        //     amount: 0
        //   }
        // ],
        app: {
          ...state.app,
          otherCharges: {
            ...state.app.otherCharges,
            onHideOtherCharges: !state.app.otherCharges.onHideOtherCharges,
            otherChargesKey: state.app.otherCharges.otherChargesKey + 1
          }
        }
      };
    case ADD_OTHER_CHARGES_AFTER_TAX_NEW_LINE:
      return {
        ...state,
        // _otherChargesAfterTax: [
        //   ...state._otherChargesAfterTax,
        //   {
        //     refAccountId: '',
        //     name: '',
        //     accountGroupName: '',
        //     description: '',
        //     creditAmount: 0,
        //     debitAmount: 0,
        //     amount: 0
        //   }
        // ],
        // _otherChargesAfterTax: {
        //   ...state._otherChargesAfterTax,
        //   [state.app.otherCharges.otherChargesAfterTaxKey + 1]: {
        //     ...state._otherChargesAfterTax[state.app.otherCharges.otherChargesAfterTaxKey + 1],
        //     refAccountId: '',
        //     name: '',
        //     accountGroupName: '',
        //     description: '',
        //     creditAmount: 0,
        //     debitAmount: 0,
        //     amount: 0
        //   }
        // },
        app: {
          ...state.app,
          otherCharges: {
            ...state.app.otherCharges,
            onHideOtherChargesAfterTax: !state.app.otherCharges.onHideOtherChargesAfterTax,
            otherChargesAfterTaxKey: state.app.otherCharges.otherChargesAfterTaxKey + 1
          }
        }
      };
    case FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        voucherAccountList: action.response,
        voucherAccounts: {
          ...state.voucherAccounts,
          [action.voucherType]: action.response
        },
        voucherAccountListLoading: false
      };
    case FETCH_ACCOUNTS_FAILED:
      return {
        ...state,
        voucherAccountListLoading: false
      };

    case FETCH_ACCOUNTS_REQUESTED:
      return {
        ...state,
        voucherAccountList: [],
        voucherAccountListLoading: true
      };

    case COMPANY_ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        voucherAccountList: [...state.voucherAccountList, action.payload]
      };
    case COMPANY_ADD_ACCOUNT_FIELD_METADATA:
      return {
        ...state,
        addAccountField: {
          ...state.addAccountField,
          ...action.data
        }
      };
    case SET_VOUCHER_UPLOADED_DOCUMENTS:
      return {
        ...state,
        documents: action.item
      };
    case UPDATE_SELECTED_VOUCHER:
      return { ...state, _selectedVocuher: action.input };
    case FETCH_VOUCHER_BY_NUMBER_SUCCESS:
      return {
        ...state,
        _searchedVoucherByNo: action.response
      };

    case VOUCHER_DATE_RANGE:
      return {
        ...state,
        getDate: action.date
      };

    case VOUCHER_DATE:
      return {
        ...state,
        getDate: {
          ...state.getdate,
          [action.key]: action.date
        }
      };

    case SET_SUPPLIER_INVOICE_DATE:
      return {
        ...state,
        supplierInvoiceDate: action.payload
      };
    case SET_SUB_TYPE:
      return {
        ...state,
        subType: action.payload
      };
    case SET_DUE_DATE:
      let dueDate = getFormattedDateWithTime(action.payload, state._issueDateObj);
      return {
        ...state,
        dueDate: dueDate
      };
    case SET_SUPPLIER_INVOICE_NO:
      return {
        ...state,
        supplierInvoiceNo: action.payload
      };
    case SET_ESTIMATION_LINKED_ITEM_LIST:
      return {
        ...state,
        estimationLinkedItems: action.payload,
        _otherCharges: action.payload.otherCharges,
        _otherChargesAfterTax: action.payload.otherChargesAfterTax,
        _lineItems: action.payload.itemList
      };
    case VOUCHERS_LIST_REQUESTED:
      return {
        ...state,
        loadingVoucherList: true
      };
    case VOUCHERS_LIST_SUCCESS:
      return {
        ...state,
        voucherList: action.payload,
        loadingVoucherList: initialState.loadingVoucherList
      };
    case SAVED_VOUCHER_SUCCESS:
      return {
        ...state,
        savedVouchersSuccess: !state.savedVouchersSuccess
      };
    case UPDATED_VOUCHER_SUCCESS:
      return {
        ...state,
        updatedVouchersSuccess: !state.updatedVouchersSuccess
      };
    case SET_VOUCHER_TYPE:
      return {
        ...state,
        type: action.payload
      };
    case ON_CLOSE_VOUCHER_PICKER:
      return {
        ...state,
        _selectedVoucherByNo: {}
      };

    case SET_SELECTED_VOUCHER_ID:
      return {
        ...state,
        _selectedVoucherId: action.id
      };
    case SET_SELECTED_VOUCHER_NO:
      return {
        ...state,
        _selectedVoucherNo: action.voucherNo
      };
    case ON_HIDE_SHOW_LINK:
      return {
        ...state,
        app: {
          ...state.app,
          showLink: !state.app.showLink
        }
      };
    case UPDATE_BUSINESS_CONTACT:
      const currentParty = action.payload.business;
      return {
        ...state,
        selectedPartyAccount:
          currentParty === undefined || currentParty === null
            ? {}
            : { ...currentParty, id: currentParty.id || currentParty.refId || currentParty._id }
      };
    case SET_PARTY_SALES_DISCOUNT:
      return {
        ...state,
        selectedPartyAccount: {
          ...state.selectedPartyAccount,
          salesDiscounts: action.payload
        }
      };
    case SET_PARTY_PURCHASE_DISCOUNT:
      return {
        ...state,
        selectedPartyAccount: {
          ...state.selectedPartyAccount,
          purchaseDiscounts: action.payload
        }
      };
    case CLOSE_BUSINESS_CARD:
      return {
        ...state,
        selectedPartyAccount: {},
        _currentBalance: {
          value: 0,
          loading: false
        },
        app: {
          ...state.app,
          toogleBusinessCard: !state.app.toogleBusinessCard
        },
        _unpaidVoucherList: initialState._unpaidVoucherList,
        _allVoucherList: initialState._allVoucherList
      };
    case FETCH_PARTY_SALES_DISCOUNT_SUCCESS:
      return {
        ...state,
        selectedPartyAccount: {
          ...state.selectedPartyAccount,
          salesDiscounts:
            state.selectedPartyAccount.id === action.partyId ? [...action.payload] : []
        }
      };
    case FETCH_PARTY_PURCHASE_DISCOUNT_SUCCESS:
      return {
        ...state,
        selectedPartyAccount: {
          ...state.selectedPartyAccount,
          purchaseDiscounts:
            state.selectedPartyAccount.id === action.partyId ? [...action.payload] : []
        }
      };
    case UPDATE_VOUCHER_NO:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          payload: {
            ...state.purchase.payload,
            voucherNo: action.payload
          }
        }
      };
    case GET_CURRENT_BALANCE_REQUESTED:
      return {
        ...state,
        _currentBalance: {
          ...state._currentBalance,
          loading: true,
          accessGranted: true
        }
      };
    case GET_CURRENT_BALANCE_SUCCESS:
      return {
        ...state,
        _currentBalance: {
          ...state._currentBalance,
          value: action.response,
          loading: false,
          accessGranted: true
        }
      };
    case GET_CURRENT_BALANCE_FAILED:
      return {
        ...state,
        _currentBalance: {
          ...state._currentBalance,
          value: 0,
          loading: false,
          accessGranted: false
        }
      };
    case UPDATE_DATE:
      return {
        ...state,
        _issueDateObj: getFormattedDateWithTime(action.payload.date, action.payload.date)
      };
    case UPDATE_SHIPPING_FOR_SELECTED_VOUCHER:
      return {
        ...state,
        _shippingAddress: {
          address: action.address.address,
          pincode: action.address.pincode,
          city: action.address.city,
          state: action.address.state,
          country: action.address.country,
          name: action.address.name,
          id: action.address.id
        }
      };
    case TOGGLE_SHIPPING_DETAILS_LINK:
      return {
        ...state,
        app: {
          ...state.app,
          shippingDetailsLink: !state.app.shippingDetailsLink
        }
      };
    case UPDATE_SHIPPING_ADDRESS_NAME:
      return {
        ...state,
        _shippingAddress: {
          ...state._shippingAddress,
          name: action.payload
        }
      };
    case UPDATE_SHIPPING_ADDRESS:
      return {
        ...state,
        _shippingAddress: {
          ...state._shippingAddress,
          address: action.payload
        }
      };
    case UPDATE_PIN_CODE:
      return {
        ...state,
        _shippingAddress: {
          ...state._shippingAddress,
          pincode: action.payload
        }
      };

    case UPDATE_CITY:
      return {
        ...state,
        _shippingAddress: {
          ...state._shippingAddress,
          city: action.payload
        }
      };
    case UPDATE_STATE:
      return {
        ...state,
        _shippingAddress: {
          ...state._shippingAddress,
          state: action.payload // this state is shipping address state not redux state
        }
      };
    case UPDATE_COUNTRY:
      return {
        ...state,
        _shippingAddress: {
          ...state._shippingAddress,
          country: action.payload // this state is shipping address state not redux state
        }
      };
    case UPDATE_SHIPPING_GEO_LOC:
      return {
        ...state,
        _shippingAddress: {
          ...state._shippingAddress,
          geoLoc: action.geoLoc
        }
      };
    case VIEW_TAX_ANALYSIS:
      return {
        ...state,
        app: {
          ...state.app,
          summary: {
            ...state.app.summary,
            viewTaxAnalysis: !state.app.summary.viewTaxAnalysis
          }
        }
      };
    //
    //line Item fields
    case SHOW_LINE_ITEM_PICKER:
      return {
        ...state,
        app: {
          ...state.app,
          lineItem: {
            ...state.app.lineItem,
            showItemPicker: true
          }
        }
      };
    case UPDATE_LINE_ITEM_FIELD:
      return {
        ...state,
        _lineItems: state._lineItems.map((element, i) =>
          element.index === action.key
            ? {
                ...element,
                ...action.updatedData
              }
            : element
        )
      };
    case STORE_OLD_LINE_ITEM:
      return {
        ...state,
        oldLineItem: {
          ...state._lineItems[action.index]
        }
      };
    case RESET_OLD_LINE_ITEM:
      return {
        ...state,
        oldLineItem: {
          ...getLineItemSchema({}, state)
        }
      };
    case DISCOUNT_CHANGE:
      return {
        ...state,
        _lineItems: state._lineItems.map((item, i) => {
          return i === action.payload
            ? {
                ...item,
                discountUnit: item.discountUnit === RUPEES ? PERCENT : RUPEES
              }
            : item;
        })
      };
    case DELETE_LINE_ITEM:
      let currentGrpCount = state.groupWiseItemCount;
      const currentLineItem = state._lineItems[action.key];
      let groupToCodeMap = state.groupToCodeMap;
      if (currentLineItem.srNoByGroup) {
        currentGrpCount = cloneDeep(state.groupWiseItemCount);
        const currentSrCode =
          groupToCodeMap[currentLineItem.itemGroupId] ||
          currentLineItem.itemSkuBarCode.split('-')[0];
        const currentSrCodeCount = currentLineItem.srNoByGroup.split('-')[1];
        if (currentGrpCount[currentSrCode] <= 1) {
          const itemGroupId = currentLineItem.itemGroupId;
          delete groupToCodeMap[itemGroupId];
        }
        if (
          state._lineItems.length - 1 === action.key ||
          currentGrpCount[currentSrCode] === 1 ||
          currentGrpCount[currentSrCode] === parseInt(currentSrCodeCount, 10)
        ) {
          currentGrpCount[currentSrCode] = currentGrpCount[currentSrCode] - 1 || 0;
          if (!currentGrpCount[currentSrCode]) {
            delete currentGrpCount[currentSrCode];
          }
        }
      }
      const item = state._lineItems.filter((item, i) => action.key !== i);
      return {
        ...state,
        _lineItems: item,
        groupWiseItemCount: item.length === 0 ? {} : currentGrpCount,
        groupToCodeMap: groupToCodeMap
      };
    case SET_CURRENT_ITEM_TO_FOCUS:
      return { ...state, currentItemCount: action.currentItemCount };
    case UPDATE_LINE_ITEM:
      const updatedData = getLineItemSchema(action.payload, state);
      return {
        ...state,
        _lineItems: state._lineItems.map((element, i) =>
          element.itemId === updatedData.itemId
            ? {
                ...element,
                ...updatedData
              }
            : element
        )
      };
    case LINE_ITEM_INDEX:
      return {
        ...state,
        app: {
          ...state.app,
          lineItem: { ...state.app.lineItem, currentItemIndex: action.index }
        }
      };
    //TODO: updatetion reducer for lineItems is diffrent for lineItem and lineItemCrDr because of items key value pair name is not same (i.e name, itemName)
    // these reducers will be refactored and a common function to get name will be called to get item name and itemSkuBarCode etc.
    case ADD_LINE_ITEM_CRDR:
      const { selectedItems } = action;
      return {
        ...state,
        _lineItems: [
          ...state._lineItems,
          {
            qtySellPrice: selectedItems.qtySellPrice,
            hsn: selectedItems.hsn ? selectedItems.hsn : '7117',
            unit: selectedItems.unit,
            qty: selectedItems.qty ? selectedItems.qty : 1,
            itemId: selectedItems.itemId,
            discountUnit: selectedItems.discountUnit,
            itemName: selectedItems.itemName,
            taxAmount: 0,
            discountAmount: 0,
            itemSkuBarCode: selectedItems.itemSkuBarCode,
            unitPrice: selectedItems.unitPrice,
            discountValue: selectedItems.discountValue || 0,
            itemGroupId: selectedItems.itemGroupId,
            taxPercentage: selectedItems.taxPercentage ? selectedItems.taxPercentage : 3,
            partyDesignCode: selectedItems.partyDesignCode
            // showItemPicker: false
          }
        ],
        app: {
          ...state.app,
          lineItem: {
            ...state.app.lineItem,
            showItemPicker: false,
            lineItemSearchText: ''
          }
        }
      };

    //TODO: updatetion reducer for lineItems is diffrent for lineItem and lineItemCrDr because of items key value pair name is not same (i.e name, itemName)
    // these reducers will be refactored and a common function to get name will be called to get item name and itemSkuBarCode etc.

    //populate lineItem for other edit mode, check if in 'edit' then populate all the line item

    case ADD_LINE_ITEM:
      const { payload, replacedItem, voucherSettings } = action;
      // const salesDiscounts = state.selectedPartyAccount.salesDiscounts;
      //only populate for lineItems comming form voucherList
      //and update lineItem indexs so that line item populated from
      //edit mode have currect index on cliking add line +
      if (action.edit) {
        // const updatedLineItemObj = arrayToObject([...action.lineItems]);
        return {
          ...state,
          _lineItems: action.lineItems
        };
      }

      const currentObj = cloneDeep(state.groupWiseItemCount);
      const keys = Object.keys(currentObj);
      const getCurrentLineItem = getLineItemSchema(payload, state, voucherSettings[state.type]);
      if (isNaN(getCurrentLineItem.qty) || getCurrentLineItem.qty <= 1) {
        getCurrentLineItem.qty = getCurrentLineItem.boxQty * getCurrentLineItem.qtyPerBox || 1;
      }
      const currentGroupCode = getCurrentLineItem.itemSkuBarCode.split('-')[0];
      const currentItemSrCode = state.groupToCodeMap[payload.itemGroupId] || currentGroupCode;
      console.log(getCurrentLineItem);
      //Sr NO. Group Wise Logic///////

      if (keys.length > 0 && includes(keys, currentItemSrCode)) {
        /// replacedItem needed to get the SrNo gruopWise value if new item has same group ID

        if (replacedItem && replacedItem.itemGroupId === payload.itemGroupId) {
          getCurrentLineItem.srNoByGroup = replacedItem.srNoByGroup;
        } else {
          currentObj[currentItemSrCode] = currentObj[currentItemSrCode] + 1;
          getCurrentLineItem.srNoByGroup = `${currentItemSrCode}-${currentObj[currentItemSrCode]}`;
        }
      } else {
        currentObj[currentItemSrCode] = 1;
        getCurrentLineItem.srNoByGroup = `${currentItemSrCode}-1`;
      }
      getCurrentLineItem.loadItemQty = action.loadItemQty;
      /////////////////////////////
      let tempLineItems = [...state._lineItems];
      tempLineItems.splice(action.index, 0, { ...getCurrentLineItem });
      return {
        ...state,
        _lineItems: tempLineItems,
        groupWiseItemCount: {
          ...currentObj
        },
        groupToCodeMap: {
          ...state.groupToCodeMap,
          [payload.itemGroupId]: currentItemSrCode
        },
        app: {
          ...state.app,
          lineItem: {
            ...state.app.lineItem,
            showItemPicker: false,
            lineItemSearchText: ''
          }
        }
      };
    case UPDATE_LINE_ITEM_SR_NOS:
      return {
        ...state,
        _lineItems: action.updatedLineItems
      };
    case REFRESH_LINE_ITEM:
      return {
        ...state,
        _lineItems: state._lineItems.map((element, i) =>
          i === action.index
            ? {
                ...element,
                ...getLineItemSchema(action.payload, state, action.voucherSettings[state.type]),
                description: element.description,
                boxQty: element.boxQty,
                qty: element.qty,
                // unitPrice: element.unitPrice,
                discountValue: element.discountValue,
                discountUnit: element.discountUnit
              }
            : element
        )
      };
    case UPDATE_GROUP_WISE_ITEM_COUNT:
      return {
        ...state,
        groupWiseItemCount: action.payload
      };
    case UPDATE_GROUP_TO_CODE_MAP:
      return {
        ...state,
        groupToCodeMap: action.groupToCodeMap
      };
    case FETCH_ITEM_DETAILS_REQUESTED:
      return {
        ...state,
        itemDetailsRequested: true
      };
    case FETCH_ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        itemDetailsRequested: false
      };
    case FETCH_ITEM_DETAILS_FAILED:
      return {
        ...state,
        itemDetailsRequested: false
      };
    case UPDATE_LAST_LINE_ITEM_PRICE:
      let currentLineItems = cloneDeep(state._lineItems);
      currentLineItems[action.key].mrp = action.payload.mrp;
      currentLineItems[action.key].unitPrice = action.payload.unitPrice;
      currentLineItems[action.key].discountValue = action.payload.discountValue;
      currentLineItems[action.key].discountUnit = action.payload.discountUnit;
      return {
        ...state,
        _lineItems: currentLineItems
      };

    case ADD_EXPENSE_LINE:
      return {
        ...state,
        _lineItems: action.itemList.map((item, index) =>
          index === action.index
            ? {
                ...state._lineItems[index],
                ...item,
                showItemPicker: false
              }
            : {
                ...state._lineItems[index]
              }
        )
      };
    case ADD_EXPENSE_LINE_ITEM:
      return {
        ...state,
        addAccountField: {
          ...state.addAccountField,
          account: action.account
        }
      };
    case ADD_RP_ACCOUNT:
      return {
        ...state,
        addAccountField: {
          ...state.addAccountField,
          account: action.account
        }
      };

    case FETCH_UNPAID_VOUCHERS_SUCCESS:
      return {
        ...state,
        againstVouchersLoading: false,
        [action.key]: action.response
      };
    case CLEAR_UNPAID_VOUCHERS:
      return {
        ...state,
        againstVouchersLoading: false,
        [action.key]: state[action.key]
      };
    case FETCH_UNPAID_VOUCHERS_FAILURE:
      return {
        ...state,
        againstVouchersLoading: false,
        [action.key]: action.error
      };
    case FETCH_UNPAID_VOUCHERS_REQUESTED:
      return {
        ...state,
        againstVouchersLoading: true,
        [action.key]: initialState[action.key]
      };
    case ADD_LINE:
      return {
        ...state,
        app: {
          ...state.app,
          otherCharges: {
            ...state.app.otherCharges,
            onHideOtherChargesAfterTax: !state.app.otherCharges.onHideOtherChargesAfterTax
          }
        }
      };

    //other charges accounts fields
    case UPDATE_ACCOUNT_FIELD:
      return {
        ...state,
        _otherChargesAfterTax: {
          ...state._otherChargesAfterTax,
          name: action.payload.input
        }
      };

    case CURRENT_OTHER_CHARGES_AFTER_TAX_INDEX_FOCUS:
      return {
        ...state,
        app: {
          ...state.app,
          otherCharges: {
            ...state.app.otherCharges,
            currentChargesAfterTaxIndex: action.index
          }
        }
      };
    //TODO: bookmark fix this yogesh
    case UPDATE_FILTERED_NAMES:
      return {
        ...state,
        _otherChargesAfterTax:
          action.mode === 'edit'
            ? state._otherChargesAfterTax.map((item, index) => {
                return index === action.index ? { ...item, ...action.data } : item;
              })
            : [...state._otherChargesAfterTax, { ...action.data }],
        app: {
          ...state.app,
          otherCharges: {
            ...state.app.otherCharges,
            onHideOtherChargesAfterTax: false
          }
        }
        // _otherChargesAfterTax: state._otherChargesAfterTax.map((item, index) => {
        //   return index === action.index ? { ...action.data } : item;
        // })
        // _otherChargesAfterTax: {
        //   ...state._otherChargesAfterTax,
        //   [state.app.otherCharges.currentChargesAfterTaxIndex]: action.data
        // }
      };

    case UPDATE_CHARGES:
      let ocAfterTaxArray = state._otherChargesAfterTax.map((item, index) => {
        return index === action.payload.index
          ? {
              ...item,
              amount: action.payload.amount,
              value: action.payload.value,
              unit: action.payload.unit
            }
          : item;
      });
      return {
        ...state,
        _otherChargesAfterTax: ocAfterTaxArray,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          otherChargesAfterTaxTotal: ocAfterTaxArray.reduce((prev, curr) => prev + curr?.amount, 0)
        }
      };
    case ACCOUNTS_NAME:
      return {
        ...state,
        accountsName: action.payload
      };
    case DRAWER_OPEN_ADD_ACCOUNTS:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          openAddAccount: true
        }
      };
    case ON_FOCUS_CURRENT_INDEX:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          currentAfterTaxIndex: action.payload
        }
      };
    case SET_ROUND_OFF_VALUE:
      return {
        ...state,
        _roundOffValue: action.value
      };
    case SET_ROUND_OFF_OFFSET:
      return {
        ...state,
        _roundOffOffset: action.value
      };
    case DELETE_CHRAGES:
      return {
        ...state,
        // _otherCharges: omit(state._otherCharges, action.index)
        _otherCharges: state._otherCharges.filter((item, index) => index !== action.index)
      };
    case DELETE_OTHER_CHRAGES_AFTER_TAX:
      return {
        ...state,
        _otherChargesAfterTax: state._otherChargesAfterTax.filter(
          (item, index) => index !== action.index
        )
        // _otherChargesAfterTax: omit(state._otherChargesAfterTax, action.index)
      };
    case HIDE_SHIPPING_DETAILS:
      return {
        ...state,
        app: {
          ...state.app,
          hideShippingDetails: action.payload
        }
      };
    case POPULATE_OTHER_CHARGES:
      return {
        ...state,
        // _otherCharges: { ...action.otherChragesArray }
        _otherCharges: [...action.otherChragesArray]
      };
    case POPULATE_ADDTIONAL_DISCOUNT:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          additionalDiscount: { ...action.additionalDiscount }
        }
      };
    case POPULATE_COUPON_DISCOUNT:
      return {
        ...state,
        couponDiscount: { ...action.couponDiscount }
      };
    //Wrong implementation need to fix it
    case SET_DISCOUNT_UNITS:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          discountImg: !state.purchase.discountImg,
          additionalDiscount: {
            ...state.purchase.additionalDiscount,
            unit: action.value
          }
        }
      };
    case SET_DISCOUNT_VALUE:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          additionalDiscount: {
            ...state.purchase.additionalDiscount,
            value: action.payload
          }
        }
      };
    case RESET_ADD_DISCOUNT:
      return {
        ...state,
        app: {
          ...state.app,
          otherCharges: {
            ...state.app.otherCharges,
            onHideAddDiscount: !state.app.otherCharges.onHideAddDiscount
          }
        }
      };
    case TOGGLE_OTHER_CHARGES:
      return {
        ...state,
        app: {
          ...state.app,
          otherCharges: {
            ...state.app.otherCharges,
            onHideOtherCharges: !state.app.otherCharges.onHideOtherCharges
          }
        }
      };

    case CURRENT_CHARGE_INDEX_FOCUS:
      return {
        ...state,
        app: {
          ...state.app,
          otherCharges: {
            ...state.app.otherCharges,
            currentChargeIndex: action.index
          }
        }
      };
    case UPDATE_ACCOUNT:
      return {
        ...state,
        _otherCharges:
          action.mode === EDIT
            ? state._otherCharges.map((item, index) => {
                return index === action.index ? { ...item, ...action.data } : item;
              })
            : [...state._otherCharges, { ...action.data }],
        // _otherCharges: state._otherCharges.map((item, index) => {
        //   return index === action.index ? { ...action.data } : item;
        // })
        // _otherCharges: {
        //   ...state._otherCharges,
        //   [state.app.otherCharges.currentChargeIndex]: action.data
        // }
        app: {
          ...state.app,
          otherCharges: {
            ...state.app.otherCharges,
            onHideOtherCharges: false
          }
        }
      };

    case POPULATE_ACCOUNTS_SUGGESTION:
      const account = action.payload;
      return {
        ...state,
        purchase: {
          ...state.purchase,
          tempCharges: {
            ...state.purchase.tempCharges,
            [state.purchase.currentChargeIndex]: {
              refAccountId: account.id,
              name: account.name,
              accountGroupName: account.accountGroupName,
              description: account.description,
              creditAmount: 0,
              debitAmount: 0
            }
          }
        }
      };
    case UPDATE_AMOUNT:
      return {
        ...state,
        _otherCharges: state._otherCharges.map((item, index) => {
          return index === action.index
            ? {
                ...item,
                amount: action.amount,
                unit: action.unit,
                value: action.value
              }
            : item;
        })
        // _otherCharges: {
        //   ...state._otherCharges,
        //   [action.index]: {
        //     ...state._otherCharges[action.index],
        //     amount: action.amount
        //   }
        // }
      };
    case DELETE_CHARGES:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          tempCharges: omit(state.purchase.tempCharges, action.payload)
        }
      };
    case UPDATE_TAX_BRACKUP:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          taxBreakup: action.payload.taxBreakup,
          totalTax: action.payload.totalTax
        }
      };

    case SET_SHIPPING_ADDRESS:
      if (Object.keys(action.address).length > 0) {
        return {
          ...state,

          _shippingAddress: {
            ...state._shippingAddress,
            city: action.address.city,
            state: action.address.state,
            address: action.address.address,
            pincode: action.address.pincode,
            country: action.address.country,
            name: action.address.name,
            id: action.address.id
          }
        };
      }
      return {
        ...state,

        _shippingAddress: {
          ...state._shippingAddress,
          city: state.selectedPartyAccount.city,
          state: state.selectedPartyAccount.state,
          address: state.selectedPartyAccount.address,
          pincode: state.selectedPartyAccount.pincode,
          country: action.address.country
        }
      };
    case UPDATE_NARRATION:
      return {
        ...state,

        _narration: action.payload
      };

    case UPDATE_PARTY_NAME:
      return {
        ...state,
        partyNameSearchText: action.input
      };
    case FETCH_ALL_BRANCHES_SUCCESS:
      return {
        ...state,
        _branchesLoading: false,
        _branches: action.response
      };
    case FETCH_ALL_AJ_TALLY_BRANCHES_SUCCESS:
      return {
        ...state,
        _aj_tally_branchesLoading: false,
        _aj_tally_branches: action.payload,
        _selected_aj_tally_branch: [action.payload[0]]
      };
    case FETCH_ALL_BRANCHES_REQUESTED:
      return {
        ...state,
        _branchesLoading: true
      };
    case FETCH_ALL_AJ_TALLY_BRANCHES_REQUESTED:
      return {
        ...state,
        _aj_tally_branchesLoading: true
      };
    case FETCH_ALL_BRANCHES_FAILED:
      return {
        ...state,
        _branchesLoading: false
      };
    case FETCH_ALL_AJ_TALLY_BRANCHES_FAILED:
      return {
        ...state,
        _aj_tally_branchesLoading: false
      };
    case TOGGLE_PURCHASE_SALES_DRAWER:
      return {
        ...state,
        togglePurchaseSalesAddVoucher: !state.togglePurchaseSalesAddVoucher
      };
    // case TOGGLE_ADD_EDIT_DRAWER:
    //   return {
    //     ...state,
    //     addEditDrawerFlags: {
    //       ...state.addEditDrawerFlags,
    //       [action.key.toLowerCase()]: action.value
    //     }
    //   };
    case UPDATE_VOUCHERS_DETAILS_ACCOUNTS:
      const currentAccounts = state.voucherAccounts[action.voucherType] || [];
      const filteredAccount = find(currentAccounts, account => action.input === account.name);

      const selectedAccountData = filteredAccount ? filteredAccount : currentAccounts[0] || {};

      // const arrayToObj = selectedAccountData.reduce(
      //   (currVal, preVal) => (currVal[preVal] = preVal),
      //   {}
      // );
      return {
        ...state,
        _voucherDetailsAccounts: selectedAccountData.name,
        _selectedVoucherAccount: selectedAccountData
      };

    case VOUCHERS_DETAILS_ACCOUNTS_POPULATE:
      const populatedAccount = state.voucherAccountList.filter(
        account => action.accountId === account.id
      );
      const data = populatedAccount.reduce((currVal, preVal) => (currVal[preVal] = preVal), {});
      return {
        ...state,
        _voucherDetailsAccounts: data.name ? data.name : state._voucherDetailsAccounts,
        _selectedVoucherAccount: data.name ? data : state._selectedVoucherAccount
      };

    case FETCH_VOUCHER_SUCCESSED:
      return {
        ...state,
        _vouchersById: {
          ...state._vouchersById,
          [action.voucherType]: action.response
        }
      };
    case FETCHED_VOUCHER_TYPE:
      return {
        ...state,
        fetchedVoucherType: action.voucherType
      };

    case CURRENT_VOUCHER_LIST_SUCCESS:
      return {
        ...state,
        _vouchersList: {
          ...state._vouchersList,
          [action.voucherType]: action.response
        }
      };

    //reset reducers
    case RESET_TRANSPORT_DETAILS:
      return {
        ...state,
        _transport: {
          ...initialState._transport
        }
      };
    case RESET_LINE_ITEMS_DETAILS:
      return {
        ...state,
        _lineItems: []
      };
    case RESET_VOUCHER_DETAILS:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          payload: {
            ...state.purchase.payload,
            voucherNo: ''
          }
        },
        app: {
          ...state.app,
          showVoucherDetailsCard: false
        },
        _selectedVoucherNo: '',
        _branchSearchText: '',
        _issueDateObj: getFormattedDateWithTime(),
        _voucherDetailsAccounts: '',
        _selectedVoucherAccount: {},
        supplierInvoiceDate: getFormattedDate3(),
        dueDate: getFormattedDateWithTime(),
        supplierInvoiceNo: ''
      };
    case RESET_SHIPPING_DETAILS:
      return {
        ...state,
        app: {
          ...state.app,
          hideShippingDetails: false
        },
        _shippingAddress: {
          city: '',
          state: '',
          address: '',
          pincode: 0,
          country: '',
          name: '',
          id: ''
        }
      };
    case RESET_VOUCHER_LINE_ITEMS:
      return {
        ...state,
        _lineItems: [],
        groupWiseItemCount: {},
        groupToCodeMap: {},
        _voucherModuleResults: {
          lineAmountSum: 0,
          billTaxAmount: '',
          billFinalAmount: '',
          autoPopulateRoundoffData: []
        },
        _otherChargesAfterTax: [],
        // _otherChargesAfterTax: {
        //   0: {
        //     refAccountId: '',
        //     name: '',
        //     accountGroupName: '',
        //     description: '',
        //     creditAmount: 0,
        //     debitAmount: 0,
        //     amount: 0
        //   }
        // },
        otherChargesDataSourceAfterTax: [],
        charges: {
          discount: {
            value: 0,
            isPercent: true
          },
          additional: {}
        },
        voucherLineItems: []
      };
    //end
    case BRANCH_SEARCH_TEXT:
      const currentBranch = state._branches.filter(branch => action.input === branch.name);

      return {
        ...state,
        _selectedBranch:
          Boolean(state._branches.length) && state._branches.length > 1
            ? currentBranch.length > 0
              ? currentBranch[0]
              : {
                  state: '',
                  id: '',
                  gstBusinessType: ''
                }
            : state._branches[0]
      };
    case SET_SELECTED_BRANCH:
      return {
        ...state,
        _selectedBranch: action.branch
      };
    case SET_INWARD_OUTWARD_SUBTYPE:
      return {
        ...state,
        inwardOutwardSubtype: action.subtype
      };
    case SET_SELECTED_DESTINATION_BRANCH:
      return {
        ...state,
        _selectedDestinationBranch: action.branch
      };
    case VOUCHER_BRANCH_POPULATE:
      const branch =
        Boolean(state._branches.length) && state._branches.length > 1
          ? state._branches.filter(branch => {
              if (action.branchId === branch.id) {
                return branch;
              }
              return null;
            })[0]
          : state._branches[0];
      return {
        ...state,
        _branchSearchText: branch.name,
        _selectedBranch: branch
      };
    // case HANDLE_ITEM_CALCULATIONS:
    //   if (action.calculatedValue.actionType === 'delete') {
    //     return {
    //       ...state,
    //       _voucherModuleResults: { ...action.calculatedValue }
    //     };
    //   }
    //   return {
    //     ...state,
    //     // _lineItems: [...action.calculatedValue.calculatedItems],
    //     _voucherModuleResults: {
    //       ...state._voucherModuleResults,
    //       ...action.calculatedValue
    //     },
    //     // _roundOffValue: action.calculatedValue.billFinalAmount
    //   };

    /*=============================Voucher calc related start===========================*/
    case GET_CALCULATED_ITEMS:
      return {
        ...state,
        _lineItems: [...action.payload]
      };
    case GET_CALCULATED_ITEM:
      return {
        ...state,
        _lineItems: state._lineItems.map((element, i) =>
          i === action.index
            ? {
                ...element,
                ...action.payload
              }
            : element
        ),
        oldLineItem: {
          //set old to new after calculation
          ...action.payload
        }
      };
    case GET_AUTO_POPULATE_ROUNDOFF_DATA:
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          autoPopulateRoundoffData: [...action.payload]
        }
      };
    case GET_LINE_AMOUNT_SUM:
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          lineAmountSum: action.payload
        }
      };
    case GET_BILL_TAX_AMOUNT:
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          billTaxAmount: action.payload
        }
      };
    case GET_BILL_ITEMS_PRICE:
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          billItemsPrice: action.payload
        }
      };
    case GET_BILL_DISCOUNT_AMOUNT:
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          billDiscountAmount: action.payload
        }
      };
    case GET_BILL_AMT_AND_DUE_AMT:
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          dueAmount: action.payload.dueAmount,
          billFinalAmount: action.payload.billFinalAmount
        },
        _roundOffValue: action.payload.billFinalAmount
      };
    case GET_OTHER_CHARGES_TOTAL:
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          otherChargesTotal: action.payload
        }
      };
    case GET_OTHER_CHARGES_AFTER_TAX_TOTAL:
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          otherChargesAfterTaxTotal: action.payload
        }
      };
    case GET_ADDITIONAL_DISCOUNT_CURRENCY_AMOUNT:
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          additionalDiscountCurrencyAmount: action.payload
        }
      };
    case GET_TAX:
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          tax: action.payload
        }
      };
    case GET_TAX_ANALYSIS:
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          taxAnalysis: action.payload
        }
      };
    case GET_EXCLUSIVE_DISCOUNT_LIST_AND_TOTAL:
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          exclusiveDiscountList: action.payload.exclusiveDiscountList,
          exclusiveDiscountTotal: action.payload.exclusiveDiscountTotal
        }
      };
    case SET_CALCULATION_STATUS:
      return {
        ...state,
        calcStatus: {
          ...state.calcStatus,
          [action.key]: action.status
        }
      };
    case VOUCHER_CALC_TOGGLE:
      return {
        ...state,
        app: {
          ...state.app,
          calcEnabled: action.flag
        }
      };
    /*=============================Voucher calc related end=======++====================*/

    case UPDATE_EXCLUSIVE_DISCOUNT_DATA:
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          exclusiveDiscountList: action.payload.exclusiveDiscountList,
          exclusiveDiscountTotal: action.payload.exclusiveDiscountTotal
        },
        app: {
          ...state.app,
          exclusiveDiscountFlag: action.payload.exclusiveDiscountFlag
        }
      };

    case RESET_VOUCHER_FORM:
      return {
        ...initialState,
        journalPayload: cloneDeep(journalInitPayload),
        type: state.type,
        togglePurchaseSalesAddVoucher: state.togglePurchaseSalesAddVoucher,
        voucherAccountList: state.voucherAccountList,
        voucherAccounts: state.voucherAccounts,
        accountsName: state.accountsName,
        voucherList: state.voucherList,
        _vouchersById: state._vouchersById,
        fetchedVoucherType: state.fetchedVoucherType,
        _branches: state._branches,
        getDate: {
          startDate: state.getDate.startDate,
          endDate: state.getDate.endDate || moment()._d
        },
        _selectedVoucherByNo: state._selectedVoucherByNo,
        voucherItems: state.voucherItems,
        paymentMethods: state.paymentMethods,
        _vouchersList: state._vouchersList,
        filters: state.filters,
        parentVoucherInfo: state.parentVoucherInfo,
        childOtherChargesDiff: state.childOtherChargesDiff,
        showChildUpdateError: state.showChildUpdateError,
        voucherTypes: state.voucherTypes,
        hiddenVoucherList: state.hiddenVoucherList,
        lastSavedVoucherDates: state.lastSavedVoucherDates,
        lastSavedVoucherPickupLocation: state.lastSavedVoucherPickupLocation,
        lastSavedVoucherTransport: state.lastSavedVoucherTransport,
        companyVoucherSettings: state.companyVoucherSettings
      };
    case RESET_VOUCHER_ITEMS:
      return {
        ...state,
        voucherItems: []
      };
    case TOGGLE_VOUCHER_DETAILS_LINK:
      return {
        ...state,
        app: {
          ...state.app,
          voucherDetailsLink: action.showLinkValue === false ? false : !state.app.voucherDetailsLink
        }
      };
    case TOGGLE_ALERT_DIALOG:
      return {
        ...state,
        app: {
          ...state.app,
          showAlertFlag: !state.app.showAlertFlag,
          alertMessage: action.message
        }
      };
    case UPDATE_TRANSPORT_FOR_SELECTED_VOUCHER:
      return {
        ...state,
        _transport: {
          transporterId: action.address.transporterId,
          name: action.address.name,
          date: getFormattedDate3(action.address.date),
          deliveryNote: action.address.deliveryNote,
          destination: action.address.destination,
          dispatchDocNo: action.address.dispatchDocNo,
          dispatchThrough: action.address.dispatchThrough,
          motorVehicleNo: action.address.motorVehicleNo,
          ewayBillDate: action.address.ewayBillDate,
          ewayBillNo: action.address.ewayBillNo,
          transporterMode: action.address.transporterMode
        },
        app: {
          ...state.app,
          showTransportCard: !state.app.showTransportCard,
          transportationLink: false
        }
      };
    case POPULATE_OTHER_CHARGES_AFTER_TAX:
      return {
        ...state,
        _otherChargesAfterTax: [...action.otherChargesAfterTax]
      };
    case POPULATE_BILL_FINAL_AMOUNT: //populate initial value on initial edit setup
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          billFinalAmount: action.billFinalAmount
        }
      };
    case POPULATE_LINE_AMOUNT_SUM: //populate initial value on initial edit setup
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          lineAmountSum: action.lineAmountSum
        }
      };
    case POPULATE_OTHER_CHARGES_TOTAL: //populate initial value on initial edit setup
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          otherChargesTotal: action.otherChargesTotal
        }
      };
    case POPULATE_OTHER_CHARGES_AFTER_TAX_TOTAL: //populate initial value on initial edit setup
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          otherChargesAfterTaxTotal: action.otherChargesAfterTaxTotal
        }
      };
    case POPULATE_BILL_TAX_AMOUNT: //populate initial value on initial edit setup
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          billTaxAmount: action.billTaxAmount
        }
      };
    case POPULATE_BILL_ITEMS_PRICE: //populate initial value on initial edit setup
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          billItemsPrice: action.billItemsPrice
        }
      };
    case POPULATE_BILL_TAX: //populate initial value on initial edit setup
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          tax: action.tax
        }
      };
    case POPULATE_BILL_DISCOUNT_AMOUNT: //populate initial value on initial edit setup
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          billDiscountAmount: action.billDiscountAmount
        }
      };
    case POPULATE_ADDITIONAL_DISCOUNT_AMOUNT: //populate initial value on initial edit setup
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          additionalDiscountCurrencyAmount: action.additionalDiscountCurrencyAmount
        }
      };
    case POPULATE_DUE_AMOUNT: //populate initial value on initial edit setup
      return {
        ...state,
        _voucherModuleResults: {
          ...state._voucherModuleResults,
          dueAmount: action.dueAmount
        }
      };

    case POPULATE_ROUNDOFF_VALUE:
      return {
        ...state,
        _roundOffValue: action.roundOffValue
      };
    case SET_LINE_ITEM_SEARCH_TEXT:
      return {
        ...state,
        app: {
          ...state.app,
          lineItem: {
            ...state.app.lineItem,
            lineItemSearchText: action.text
          }
        }
      };
    case TOGGLE_TRANSPORT_LINK:
      return {
        ...state,
        app: {
          ...state.app,
          transportationLink: !state.app.transportationLink
        }
      };
    case FETCH_BANK_LIST_SUCCESS:
      return {
        ...state,
        bankList: action.payload
      };
    case FETCH_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethods: action.payload
      };
    case FETCH_VOUCHER_TYPES_SUCCESS:
      return {
        ...state,
        voucherTypes: action.payload
      };
    case FETCH_VOUCHER_TYPES_FAILED:
      return {
        ...state,
        voucherTypes: []
      };
    case FETCH_SHARE_VOUCHER_DATA_REQUESTED:
      return {
        ...state,
        shareDataLoading: true
      };
    case FETCH_SHARE_VOUCHER_DATA_SUCCESS:
      return {
        ...state,
        shareDataLoading: false,
        shareData: {
          ...state.shareData,
          shareContacts: action.payload
        }
      };
    case SHARE_VOUCHER_REQUESTED:
      return {
        ...state,
        shareVoucherRequested: true
      };
    case SHARE_VOUCHER_SUCCESS:
      return {
        ...state,
        shareVoucherRequested: false
      };
    case SHARE_VOUCHER_FAILED:
      return {
        ...state,
        shareVoucherRequested: false
      };
    case FETCH_SHARE_VOUCHER_DATA_FAILED:
      return {
        ...state,
        shareDataLoading: false,
        shareData: {
          ...state.shareData,
          shareContacts: []
        }
      };
    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        shareDataLoading: false,
        shareData: {
          ...state.shareData,
          contacts: action.payload
        }
      };
    case FETCH_CONTACTS_FAILED:
      return {
        ...state,
        shareDataLoading: false,
        shareData: {
          ...state.shareData,
          contacts: []
        }
      };
    case FETCH_VOUCHER_ITEMS_REQUESTED:
      return {
        ...state,
        voucherItemsLoading: true,
        voucherItems: []
      };
    case FETCH_VOUCHER_ITEMS_SUCCESS:
      return {
        ...state,
        voucherItemsLoading: false,
        voucherItems: action.payload
      };
    case FETCH_VOUCHER_ITEMS_FAILED:
      return {
        ...state,
        voucherItemsLoading: false,
        voucherItems: []
      };
    case FETCH_SHARE_VOUCHER_PUBLIC_DATA_REQUESTED:
      return {
        ...state,
        publicShareStatus: {
          ...initialState.publicShareStatus,
          loading: true
        }
      };
    case FETCH_SHARE_VOUCHER_PUBLIC_DATA_SUCCESS:
      return {
        ...state,
        publicShareStatus: {
          ...state.publicShareStatus,
          loading: false,
          ...action.payload
        }
      };
    case FETCH_SHARE_VOUCHER_PUBLIC_DATA_FAILED:
      return {
        ...state,
        publicShareStatus: {
          ...state.publicShareStatus,
          loading: false
        }
      };
    case FETCH_VOUCHER_DUE_AMOUNT_REQUESTED:
      return {
        ...state,
        voucherDueAmountRequested: true
      };
    case FETCH_VOUCHER_DUE_AMOUNT_SUCCESS:
      return {
        ...state,
        voucherDueAmountRequested: false
      };
    case FETCH_VOUCHER_DUE_AMOUNT_FAILED:
      return {
        ...state,
        voucherDueAmountRequested: false
      };
    case PRESERVE_VOUCHER_DATES:
      return {
        ...state,
        lastSavedVoucherDates: {
          ...state.lastSavedVoucherDates,
          ...action.payload
        }
      };
    case SET_VOUCHER_CREATION_MODE:
      return {
        ...state,
        posBilling: action.payload
      };
    case POS_BILLING_PARTY:
      return {
        ...state,
        posBillingParty: action.payload
      };
    case SET_NARRATION_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          narration: action.payload
        }
      };
    case SET_EDIT_VOUCHER_ID:
      return {
        ...state,
        editVoucherId: action.editVoucherId
      };
    case UPDATE_LINKED_ESTIMATION_STATUS:
      return {
        ...state,
        linkedVoucherUpdate: {
          ...state.linkedVoucherUpdate,
          loading: action.loading,
          status: action.status
        }
      };
    case SET_COMPANY_VOUCHER_SETTINGS:
      return {
        ...state,
        companyVoucherSettings: action.payload
      };
    default:
      return state;
  }
};

const itemAvaliableQtyList = (state = itemAvaliableQtyInitial, action) => {
  switch (action.type) {
    case FETCH_LINE_ITEM_AVALIABLE_QTY_REQUESTED:
      return {
        ...state,
        list: {
          ...state.list,
          [action.itemId]: {
            ...state[action.itemId],
            loading: true
          }
        }
      };
    case FETCH_LINE_ITEM_AVALIABLE_QTY_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          [action.itemId]: {
            ...state[action.itemId],
            ...action.payload,
            loading: false
          }
        }
      };
    case FETCH_LINE_ITEM_AVALIABLE_QTY_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          [action.itemId]: {
            ...state[action.itemId],
            loading: false
          }
        }
      };
    case POPULATE_ITEM_QTYS_TOTAL:
      return {
        ...state,
        voucherQtys: action.qtys
      };
    case RESET_ITEM_AVAILABLE_QTY_LIST:
      return {
        ...itemAvaliableQtyInitial
      };
    default:
      return state;
  }
};

// const bankList = (state = [], action) => {
//   console.log(action);
//   switch (action.type) {
//     case FETCH_BANK_LIST_SUCCESS:
//       return {
//         ...state,
//         fata: action.payload
//       };
//     default:
//       return state;
//   }
// };

// const paymentMethods = (state = [], action) => {
//   console.log(action);
//   switch (action.type) {
//     case FETCH_PAYMENT_METHOD_SUCCESS:
//       return action.payload;
//     default:
//       return state;
//   }
// };

const initialStateGetVocuher = {
  loadingGetVoucher: false,
  voucher: null
};

const selectedVoucher = (state = initialStateGetVocuher, action) => {
  switch (action.type) {
    case GET_VOUCHER_REQUESTED:
      return {
        ...state,
        loadingGetVoucher: true
      };
    case GET_VOUCHER_SUCCESS:
      return {
        ...state,
        voucher: action.payload,
        loadingGetVoucher: initialState.loadingGetVoucher
      };
    default:
      return state;
  }
};

//selectors
const getVoucherDetails = ({
  vouchers: {
    purchase: {
      payload: { voucherNo }
    },
    _selectedVoucherNo,
    _branchSearchText,
    _issueDateObj,
    _voucherDetailsAccounts
  }
}) => {
  return {
    voucherDetails: {
      voucherNo: _selectedVoucherNo,
      branch: _branchSearchText,
      date: _issueDateObj,
      accounts: _voucherDetailsAccounts
    }
  };
};
const getShowLink = ({
  vouchers: {
    app: { showLink }
  }
}) => showLink;

const getVoucherType = ({ vouchers: { type } }) => type;
const getParty = ({ vouchers: { selectedPartyAccount } }) => selectedPartyAccount;
const getIssueDate = ({ vouchers: { _issueDateObj } }) => _issueDateObj;
const getUnpaidVouchersList = ({ vouchers: { _unpaidVoucherList } }) => _unpaidVoucherList || {};
const getAllVouchersList = ({ vouchers: { _allVoucherList } }) => _allVoucherList;
// this selectors is only Payment and receipt voucher payload genration
const getSelectedAccountDetails = ({ vouchers: { _selectedVoucherAccount } }) => {
  return {
    refAccountName: _selectedVoucherAccount.name,
    accountGroupName: _selectedVoucherAccount.accountGroupName,
    refPath: _selectedVoucherAccount.path,
    refAccountId: _selectedVoucherAccount.id
  };
};

const getTransportDetails = ({ vouchers: { _transport } }) => _transport;

const getSalesPersonDetails = ({ vouchers: { _salesPerson } }) => _salesPerson;

const getSalesPersonLinkStatus = ({
  vouchers: {
    app: { salesPersonLink }
  }
}) => salesPersonLink;

const getPickUpLocationLinkStatus = ({
  vouchers: {
    app: { pickUpLocationLink }
  }
}) => pickUpLocationLink;

const getTransportationLinkStatus = ({
  vouchers: {
    app: { transportationLink }
  }
}) => transportationLink;

const getShowTransportCard = ({
  vouchers: {
    app: { showTransportCard }
  }
}) => showTransportCard;
const getShowCardForVoucherDetails = ({
  vouchers: {
    app: { showVoucherDetailsCard }
  }
}) => showVoucherDetailsCard;

const getVoucherByNumber = ({ vouchers: { _searchedVoucherByNo } }) => {
  return _searchedVoucherByNo.map(voucher => {
    return {
      voucherNo: voucher.voucherNo,
      id: voucher.id,
      partyName: voucher.party.name,
      party: voucher.party,
      itemList: voucher.itemList
    };
  });
};

const getSelectedPchallanVoucher = ({
  vouchers: {
    _vouchersById: { pchallan }
  }
}) => pchallan;

const getSelectedPurchaseVoucher = ({
  vouchers: {
    _vouchersById: { purchase }
  }
}) => purchase;

const getSelectedExpenseVoucher = ({
  vouchers: {
    _vouchersById: { expense }
  }
}) => expense;

const getSelectedSalesVoucher = ({
  vouchers: {
    _vouchersById: { sales }
  }
}) => sales;
const getSelectedStockVoucher = ({ vouchers: { _vouchersById, fetchedVoucherType } }, type) =>
  _vouchersById[fetchedVoucherType];

const getSelectedSchallanVoucher = ({
  vouchers: {
    _vouchersById: { schallan }
  }
}) => schallan;
const getSelectedSestimationVoucher = ({
  vouchers: {
    _vouchersById: { sestimation }
  }
}) => sestimation;
const getSelectedPestimationVoucher = ({
  vouchers: {
    _vouchersById: { pestimation }
  }
}) => pestimation;
const getSelectedCreditNote = ({
  vouchers: {
    _vouchersById: { credit_note }
  }
}) => credit_note;
const getSelectedDebitNote = ({
  vouchers: {
    _vouchersById: { debit_note }
  }
}) => debit_note;

const getListOfCurrentVoucher = (state, voucherType) => {
  const {
    vouchers: { _vouchersList, type }
  } = state;
  return _vouchersList[voucherType || type];
};

const getCurrentVoucherListPageNo = (state, voucherType) => {
  const {
    vouchers: { _vouchersList, type }
  } = state;
  return _vouchersList[voucherType || type].page ? _vouchersList[voucherType || type].page : 1;
};

const getListOfStockVoucher = state => {
  const {
    vouchers: { _vouchersList }
  } = state;
  return _vouchersList[VOUCHER_TYPE_STOCK_TRANSFER];
};

const getChallanListForSPVoucher = ({ vouchers: { challanListForSPVoucher } }) =>
  challanListForSPVoucher;

const getSelectedVoucherDetails = ({ vouchers: { _selectedVocuher } }) => _selectedVocuher;
const getStartDate = ({
  vouchers: {
    getDate: { startDate }
  }
}) => startDate;
const getselectedBusinessAccount = ({ selectedPartyAccount }) => selectedPartyAccount; //TODO: hook it properly for selected vouchers
const getShippingAddress = ({ _shippingAddress }) => _shippingAddress;
const getCurrentBalance = ({ _currentBalance: currentBalance }) => currentBalance;
const getToogleBusinessCard = ({ app: { toogleBusinessCard } }) => toogleBusinessCard;
const getSearchText = vouchers => {
  const { partyNameSearchText } = vouchers;
  return partyNameSearchText;
};
const getopenAddDrawer = ({ vouchers: { togglePurchaseSalesAddVoucher } }) =>
  togglePurchaseSalesAddVoucher;
const getNarrationValue = ({ vouchers: { _narration } }) => _narration;
const getSummaryBlockData = ({ vouchers: { roundoffValue, _voucherModuleResults } }) => ({
  roundoffValue,
  ..._voucherModuleResults
});
const getbusinessAccountsSuggestions = ({ vendorAccountsDataList }) => vendorAccountsDataList;
const getAccountsName = currentCompany => {
  const accountNameArray = currentCompany
    ? map(currentCompany.expenseIncomeAccounts, accountInfo => accountInfo)
    : [];
  return accountNameArray;
};
const getTotalQty = ({
  vouchers: { _lineItems, type, subType },
  currentCompany: { itemQtyDecimals }
}) => {
  const add = (a, b) => a + b;
  let key = 'qty';

  if (
    (type === VOUCHER_TYPE_CREDIT_NOTE || type === VOUCHER_TYPE_DEBIT_NOTE) &&
    subType === ADJUSTMENT_ON_ITEM_PRICE
  ) {
    key = 'adjustedQty';
  }

  const qtyArray = map(_lineItems, items => parseFloat(items[key]));
  const qty = Boolean(qtyArray.length) && qtyArray.reduce(add);

  //qroup qty by unit
  // const qtyObj = reduce(
  //   _lineItems,
  //   (prev, curr) => {
  //     prev[curr.unit] ? (prev[curr.unit] += curr.qty) : (prev[curr.unit] = curr.qty);
  //     return prev;
  //   },
  //   {}
  // );
  // let qtyStr = '';
  // for (const unit in qtyObj) {
  //   if (qtyObj.hasOwnProperty(unit)) {
  //     qtyStr += `${qtyStr ? ',' : ''} ${qtyObj[unit]} ${unit}`;
  //   }
  // }
  // return qtyStr;
  return getfloatingValue(qty, itemQtyDecimals);
};

const getTotalQtyFromSelectedVoucher = ({
  vouchers: { _vouchersById, type },
  selectedVoucher: { voucher },
  currentCompany: { itemQtyDecimals }
}) => {
  if (!voucher) return 0;
  const { itemList, lineItemQtySum } = voucher ? voucher : _vouchersById[type];
  if (!lineItemQtySum || lineItemQtySum === 0) {
    const add = (a, b) => a + b;
    const qtyArray = map(itemList, items => parseFloat(items.qty));
    const qty = Boolean(qtyArray.length) && qtyArray.reduce(add);
    return getfloatingValue(qty, itemQtyDecimals);
  }
  return lineItemQtySum;
};

const getlineAmountSum = ({
  vouchers: {
    _voucherModuleResults: { lineAmountSum = 0 }
  }
}) => lineAmountSum;
const getRoundOffValues = values => values;

const getOtherChargesAfterTax = otherCharges => otherCharges;
const getTaxAnalysisData = taxAnalysis => {
  return taxAnalysis.map(item => ({
    sNo: item.index,
    name: item.itemName,
    price: item.itemPrice,
    tax: item.itemTaxAmount,
    totalPrice: item.totalPriceWeightage,
    totalTax: item.totalTaxWeightage,
    otherChargesTaxBreakup: item.otherChargesTaxBreakup.map(otherCharges => {
      return {
        accountName: otherCharges.accountName,
        price: otherCharges.priceWeightage,
        tax: otherCharges.taxWeightage,
        formulaToDisplay: otherCharges.formulaToDisplay
      };
    })
  }));
};
const getotherChargesTaxBreakup = taxAnalysis => {
  return taxAnalysis.map(taxItem => taxItem.otherChargesTaxBreakup);
};
const getTempItems = ({
  vouchers: {
    type,
    _lineItems,
    _selectedVocuher: { lineItem }
  }
}) => {
  // if (type === 'credit_note' || type === 'debit_note') {
  //   return map(lineItem, item => ({
  //     hsn: item.hsn,
  //     qty: item.qty,
  //     unit: item.unit,
  //     itemName: item.itemName,
  //     taxPercentage: item.taxPercentage,
  //     unitSellPrice: item.unitSellPrice,
  //     showItemPicker: true,
  //     itemSkuBarCode: item.itemSkuBarCode,
  //     discountIconRupees: item.discountIconRupees,
  //     discountValue: item.discountValue,
  //     lineAmount: item.lineAmount
  //   }));
  // }
  return _lineItems;
};

const getPartyObj = party => {
  return party.id || party.refId
    ? {
        refId: party.id || party.refId,
        accountName: party.name || party.accountName || '',
        name: party.name || '',
        state: party.state || '', //party's state in address field
        gstin: party.gstin !== '' ? party.gstin : party.pancard,
        address: party.address || '',
        city: party.city || '',
        gstPartyType: party.gstBusinessType || party.gstPartyType,
        pincode: party.pincode || '',
        aliasName: party.aliasName || ''
      }
    : undefined;
};

const getSalesPersonObj = party => {
  return party.id || party.refId
    ? {
        refId: party.id || party.refId,
        accountName: party.name || party.accountName || '',
        name: party.name || '',
        state: party.state || '', //party's state in address field
        gstin: party.gstin !== '' ? party.gstin : party.pancard,
        address: party.address || '',
        city: party.city || '',
        gstPartyType: party.gstBusinessType || party.gstPartyType,
        pincode: party.pincode || '',
        aliasName: party.aliasName || ''
      }
    : {
        refId: null,
        accountName: '',
        name: '',
        state: '',
        gstin: '',
        address: '',
        city: '',
        gstPartyType: '',
        pincode: '',
        aliasName: ''
      };
};

const sortLineItemByUserPreference = (lineItems, sorting) => {
  if (sorting.length === 0) {
    return lineItems;
  }
  const { columnName, direction } = sorting[0];
  let sortedLineItems = cloneDeep(lineItems).sort(function(a, b) {
    if (columnName === 'srNoByGroup') {
      return customSortForSrNoByGroup(a[columnName], b[columnName]);
    }
    if (columnName === 'itemSkuBarCode') {
      return customSortForSku(a[columnName], b[columnName]);
    }
    if (a[columnName] < b[columnName]) return -1;
    if (a[columnName] > b[columnName]) return 1;
    return 0;
  });
  if (JSON.stringify(lineItems) === JSON.stringify(sortedLineItems) && direction === 'desc') {
    sortedLineItems = sortedLineItems.reverse();
  }
  return sortedLineItems;
};

const generatePayloadForLinkedEstimation = state => {
  const {
    vouchers,
    vouchers: {
      _salesPerson,
      challanListForSPVoucher,
      selectedPartyAccount,
      selectedPartyAccount: { salesDiscounts = [] },
      app: { exclusiveDiscountFlag },
      purchase: { additionalDiscount },
      couponDiscount,
      documents,
      groupWiseItemCount,
      _roundOffOffset,
      _pickUpLocation,
      type
    },
    currentCompany: { ewayBillEInvoiceSetup },
    table: { sorting }
    // contacts: { salesDiscounts }
  } = state;

  // manipulating itemlist for blank itemiD and unwanted fields
  const cloneItemList = cloneDeep(vouchers._lineItems);
  const maniuplateLineItems = stripLineItemsForUnwantedFields(cloneItemList);
  let lineItemsArray = maniuplateLineItems.filter(value => Object.keys(value).length !== 0);
  lineItemsArray = sortLineItemByUserPreference(lineItemsArray, sorting);
  // manipulating tax for blank taxPercentage or zero percentage
  const taxArray = getVoucherModuleResultState(vouchers).tax;
  const formattedSalesDiscounts = salesDiscounts.map(value => {
    let tempDiscount = { ...value };
    delete tempDiscount.itemGroupName;
    return tempDiscount;
  });
  // manipulating _otherCharges for blank or null refAccountId
  const cloneOtherCharges = cloneDeep(vouchers._otherCharges);
  const maniuplateOtherCharges = stripOtherCahrgesForBlankId(cloneOtherCharges);
  const otherChargesArray = maniuplateOtherCharges.filter(value => Object.keys(value).length !== 0);
  //WARNINGS: FIX  this once branch field is hooked
  const adjustments = vouchers._voucherModuleResults.autoPopulateRoundoffData.filter(
    item => item.outputValue === vouchers._roundOffValue
  );
  const party = getPartyObj(selectedPartyAccount);
  const voucherIds = [];

  const billAmountBeforeTax = getfloatingValue(
    getVoucherModuleResultState(vouchers).billFinalAmount -
      getfloatingValue(getTotalOfTax(taxArray).totalTax) -
      _roundOffOffset -
      getVoucherModuleResultState(vouchers).otherChargesAfterTaxTotal
  );

  let billDiscountAmount = getVoucherModuleResultState(vouchers).billDiscountAmount;
  let lineAmountSum = getVoucherModuleResultState(vouchers).lineAmountSum;
  const billAmountBeforeOtherCharges = getfloatingValue(lineAmountSum - billDiscountAmount);

  let adjustment =
    adjustments.length === 0
      ? 0
      : vouchers._roundOffValue !== '' &&
        getVoucherModuleResultState(vouchers).autoPopulateRoundoffData.find(
          data => data.outputValue === vouchers._roundOffValue
        ).offset;
  const billFinalAmount = getVoucherModuleResultState(vouchers).billFinalAmount;
  const otherChargesAfterTaxTotal = getVoucherModuleResultState(vouchers).otherChargesAfterTaxTotal;
  const billAmountBeforeOtherChargesAfterTax = getfloatingValue(
    billFinalAmount - adjustment - otherChargesAfterTaxTotal
  );

  challanListForSPVoucher.forEach(voucher => {
    voucherIds.push(voucher._id || voucher.refVoucherId);
  });

  let pickUpLocation = null;

  if (ewayBillEInvoiceSetup) {
    switch (vouchers._selectedVoucherAccount.name) {
      case SALES:
        pickUpLocation = {
          address: _pickUpLocation?.address,
          city: _pickUpLocation?.city,
          country: _pickUpLocation?.country,
          pincode: _pickUpLocation?.pincode,
          name: _pickUpLocation?.name,
          refBranchId: _pickUpLocation?.id,
          state: _pickUpLocation?.state
        };
        break;
      default:
        pickUpLocation = null;
    }
  }

  let salesPerson;
  if (type === VOUCHER_TYPE_SESTIMATION || type === VOUCHER_TYPE_SALES) {
    salesPerson = getSalesPersonObj(_salesPerson);
  }

  let voucherObject = {
    issueDate: getFormattedDateIso(vouchers._issueDateObj),
    dueDate: getFormattedDateIso(vouchers.dueDate),
    voucherIds,
    narration: getNarrationValue(state),
    notifyParty: true,
    internalNotes: [],
    status: 'unpaid',
    party: party,
    tax: taxArray,
    dueAmount: getVoucherModuleResultState(vouchers).dueAmount,
    adjustments: adjustment,
    billAmountBeforeTax,
    billFinalAmount,
    billItemsPrice: getVoucherModuleResultState(vouchers).billItemsPrice,
    billTaxAmount: getVoucherModuleResultState(vouchers).billTaxAmount,
    billAmountBeforeOtherCharges,
    billDiscountAmount,
    lineAmountSum,
    lineItemQtySum: getTotalQty(state),
    refAccountId: vouchers._selectedVoucherAccount.id,
    refAccountName: vouchers._selectedVoucherAccount.name,
    refAccountGroup: vouchers._selectedVoucherAccount.accountGroupName,
    supplierInvoiceNo: vouchers.supplierInvoiceNo,
    supplierInvoiceDate: vouchers.supplierInvoiceDate,
    refPath: vouchers._selectedVoucherAccount.path,
    shippingAddress: { ...vouchers._shippingAddress },
    transportation: { ...vouchers._transport },
    salesPerson: salesPerson,
    otherChargesAfterTax:
      vouchers._otherChargesAfterTax[0] && vouchers._otherChargesAfterTax[0].refAccountId === ''
        ? []
        : map(vouchers._otherChargesAfterTax, otherCharges => otherCharges),
    itemList: map(lineItemsArray, (lineItem, i) => {
      return {
        ...lineItem
      };
    }),
    otherChargesAfterTaxTotal,
    groupWiseItemCount,
    otherCharges: otherChargesArray,
    hasExclusiveDiscount: exclusiveDiscountFlag,
    exclusiveDiscountSummary: getVoucherModuleResultState(vouchers).exclusiveDiscountList,
    salesDiscounts: formattedSalesDiscounts,
    documents: getdocumentSchema(documents),
    additionalDiscount,
    couponDiscount,
    discountBreakup: getDiscountBreakup(state),
    billAmountBeforeOtherChargesAfterTax
  };

  if (pickUpLocation && pickUpLocation.state) {
    voucherObject.pickUpLocation = pickUpLocation;
  }

  return voucherObject;
};

const getDiscountBreakup = state => {
  const {
    vouchers: {
      _voucherModuleResults: {
        lineAmountSum = 0,
        billItemsPrice = 0,
        exclusiveDiscountTotal = 0,
        additionalDiscountCurrencyAmount = 0,
        billDiscountAmount = 0
      }
    },
    currentCompany: { decimalPlaces }
  } = state;

  const lineDiscountSumAmount = billItemsPrice - lineAmountSum;
  const additionalDiscountSumAmount = additionalDiscountCurrencyAmount;
  const exclusiveDiscountSumAmount = exclusiveDiscountTotal;
  const couponDiscountSumAmount =
    billDiscountAmount -
    lineDiscountSumAmount -
    additionalDiscountSumAmount -
    exclusiveDiscountSumAmount;

  return {
    lineDiscountSumAmount: getfloatingValue(lineDiscountSumAmount, decimalPlaces),
    additionalDiscountSumAmount: getfloatingValue(additionalDiscountSumAmount, decimalPlaces),
    exclusiveDiscountSumAmount: getfloatingValue(exclusiveDiscountSumAmount, decimalPlaces),
    couponDiscountSumAmount: getfloatingValue(couponDiscountSumAmount, decimalPlaces)
  };
};

const getTotalBeforeOtherCharges = state => {
  const {
    vouchers: {
      _voucherModuleResults: { lineAmountSum = 0 }
    },
    currentCompany: { currencyDecimals }
  } = state;

  const discount = getDiscountBreakup(state);
  return getfloatingValue(
    lineAmountSum -
      (discount.additionalDiscountSumAmount +
        discount.exclusiveDiscountSumAmount +
        discount.couponDiscountSumAmount),
    currencyDecimals
  );
};

const generateChallanLinkedSalesPurchasePayload = state => {
  const {
    vouchers,
    vouchers: {
      challanListForSPVoucher,
      couponDiscount,
      documents,
      selectedPartyAccount,
      _salesPerson,
      type
    }
  } = state;

  const party = getPartyObj(selectedPartyAccount);
  const voucherIds = [];

  challanListForSPVoucher.forEach(voucher => {
    voucherIds.push(voucher._id || voucher.refVoucherId);
  });

  let salesPerson;
  if (type === VOUCHER_TYPE_SCHALLAN || type === VOUCHER_TYPE_SALES) {
    salesPerson = getSalesPersonObj(_salesPerson);
  }
  return {
    issueDate: getFormattedDateIso(vouchers._issueDateObj),
    voucherIds,
    narration: getNarrationValue(state),
    notifyParty: true,
    internalNotes: [],
    status: 'unpaid',
    party: party,
    couponDiscount,
    refAccountId: vouchers._selectedVoucherAccount.id,
    refAccountName: vouchers._selectedVoucherAccount.name,
    refAccountGroup: vouchers._selectedVoucherAccount.accountGroupName,
    supplierInvoiceNo: vouchers.supplierInvoiceNo,
    supplierInvoiceDate: vouchers.supplierInvoiceDate,
    dueDate: getFormattedDateIso(vouchers.dueDate),
    refPath: vouchers._selectedVoucherAccount.path,
    shippingAddress: { ...vouchers._shippingAddress },
    transportation: { ...vouchers._transport },
    salesPerson: salesPerson,
    documents: getdocumentSchema(documents),
    otherChargesAfterTax:
      vouchers._otherChargesAfterTax[0] && vouchers._otherChargesAfterTax[0].refAccountId === ''
        ? []
        : map(vouchers._otherChargesAfterTax, otherCharges => otherCharges)
  };
};

//selectors for payload genration
const generateChallanPayload = state => {
  return generateSPECPayload(state);
};

//selectors for payload genration
const genratePurchaseSalesPayload = state => {
  return generateSPECPayload(state);
};

const generateSPECPayload = state => {
  const {
    vouchers,
    vouchers: {
      type,
      _selectedBranch,
      _selectedDestinationBranch,
      inwardOutwardSubtype,
      selectedPartyAccount,
      selectedPartyAccount: { salesDiscounts = [], purchaseDiscounts },
      documents,
      app: { exclusiveDiscountFlag },
      couponDiscount,
      groupWiseItemCount,
      _roundOffOffset,
      _salesPerson,
      recordReceiptMode,
      _pickUpLocation,
      subType
    },
    currentCompany: { ewayBillEInvoiceSetup, currencyDecimals },
    table: { sorting }
    // contacts: { salesDiscounts }
  } = state;
  // manipulating itemlist for blank itemiD and unwanted fields
  const cloneItemList = cloneDeep(vouchers._lineItems);
  const maniuplateLineItems = stripLineItemsForUnwantedFields(cloneItemList);
  let lineItemsArray = maniuplateLineItems.filter(value => Object.keys(value).length !== 0);
  lineItemsArray = sortLineItemByUserPreference(lineItemsArray, sorting);
  // manipulating tax for blank taxPercentage or zero percentage
  const manipulateTax = cloneDeep(getVoucherModuleResultState(vouchers).tax);
  const taxArray =
    manipulateTax && manipulateTax.length > 0
      ? manipulateTax.filter(value => Object.keys(value).length !== 0)
      : [];
  const formattedSalesDiscounts =
    salesDiscounts?.length > 0
      ? salesDiscounts.map(value => {
          let tempDiscount = { ...value };
          delete tempDiscount.itemGroupName;
          return tempDiscount;
        })
      : undefined;

  const formattedPurchaseDiscounts =
    purchaseDiscounts?.length > 0
      ? purchaseDiscounts.map(value => {
          let tempDiscount = { ...value };
          delete tempDiscount.itemGroupName;
          return tempDiscount;
        })
      : undefined;
  // manipulating _otherCharges for blank or null refAccountId
  const cloneOtherCharges = cloneDeep(vouchers._otherCharges);
  const maniuplateOtherCharges = stripOtherCahrgesForBlankId(cloneOtherCharges);
  const otherChargesArray = maniuplateOtherCharges.filter(
    value => Object.keys(value).length !== 0 && value.refAccountId
  );
  //WARNINGS: FIX  this once branch field is hooked
  const branchId = _selectedBranch ? _selectedBranch.id : null;
  const destinationBranchId = _selectedDestinationBranch
    ? _selectedDestinationBranch.id
    : undefined;
  const adjustments = vouchers._roundOffOffset;

  const party = getPartyObj(selectedPartyAccount);
  let salesPerson;
  const billAmountBeforeTax = getfloatingValue(
    getVoucherModuleResultState(vouchers).billFinalAmount -
      getfloatingValue(getTotalOfTax(taxArray).totalTax) -
      _roundOffOffset -
      (getVoucherModuleResultState(vouchers).otherChargesAfterTaxTotal || 0)
  );

  let billDiscountAmount = getVoucherModuleResultState(vouchers).billDiscountAmount;
  let lineAmountSum = getVoucherModuleResultState(vouchers).lineAmountSum;
  const billAmountBeforeOtherCharges = getfloatingValue(lineAmountSum - billDiscountAmount);

  const billFinalAmount = getVoucherModuleResultState(vouchers).billFinalAmount;
  const otherChargesAfterTaxTotal = getVoucherModuleResultState(vouchers).otherChargesAfterTaxTotal;
  const billAmountBeforeOtherChargesAfterTax = getfloatingValue(
    billFinalAmount - adjustments - otherChargesAfterTaxTotal
  );
  const { duplicateVoucherType } = getVoucherViewMode(state);
  const voucherType = duplicateVoucherType || type;
  // const voucherNo = vouchers._vouchersById[vouchers.type].voucherNo; //Not needed in update
  const recordRpPayload = recordReceiptMode.recordRPFlag
    ? recordReceiptMode.recordRpPayload
    : undefined;
  const recordRpVoucher = recordReceiptMode.recordRPFlag || undefined;
  let pickUpLocation = null;

  if (
    type === VOUCHER_TYPE_SALES ||
    type === VOUCHER_TYPE_SCHALLAN ||
    type === VOUCHER_TYPE_SESTIMATION
  ) {
    salesPerson = getSalesPersonObj(_salesPerson);
  }
  // PICKUP details specific for ewaybill
  if (ewayBillEInvoiceSetup) {
    switch (type) {
      case VOUCHER_TYPE_SALES:
      case VOUCHER_TYPE_CREDIT_NOTE:
      case VOUCHER_TYPE_DEBIT_NOTE:
        pickUpLocation = {
          address: _pickUpLocation?.address,
          city: _pickUpLocation?.city,
          country: _pickUpLocation?.country,
          pincode: _pickUpLocation?.pincode,
          name: _pickUpLocation?.name,
          refBranchId: _pickUpLocation?.id,
          state: _pickUpLocation?.state
        };
        break;
      default:
        pickUpLocation = null;
    }
  }

  let voucherObject = {
    iBranchId: branchId,
    refDestinationBranchId:
      voucherType === VOUCHER_TYPE_STOCK_TRANSFER ? destinationBranchId : undefined,
    subType: voucherType === VOUCHER_TYPE_STOCK_ADJUSTMENT ? inwardOutwardSubtype : undefined,
    supplierInvoiceNo: vouchers.supplierInvoiceNo,
    supplierInvoiceDate: vouchers.supplierInvoiceDate,
    dueDate: getFormattedDateIso(vouchers.dueDate),
    party: party,
    salesPerson,
    refAccountId: vouchers._selectedVoucherAccount.id,
    refAccountName: vouchers._selectedVoucherAccount.name,
    refAccountGroup: vouchers._selectedVoucherAccount.accountGroupName,
    refPath: vouchers._selectedVoucherAccount.path,
    itemList: map(lineItemsArray, (lineItem, i) => {
      return {
        ...lineItem
      };
    }),
    shippingAddress: { ...vouchers._shippingAddress },
    transportation: { ...vouchers._transport },
    otherCharges: otherChargesArray,
    groupWiseItemCount,
    otherChargesAfterTax:
      vouchers._otherChargesAfterTax[0] && !vouchers._otherChargesAfterTax[0].refAccountId
        ? []
        : map(vouchers._otherChargesAfterTax, otherCharges => otherCharges),
    // vouchers._otherChargesAfterTax[0].refAccountId !== ''
    //   ? objectToArray(vouchers._otherChargesAfterTax)
    //   : [],
    internalNotes: [],
    issueDate: getFormattedDateIso(vouchers._issueDateObj),
    tax: taxArray,
    dueAmount: getVoucherModuleResultState(vouchers).dueAmount,
    narration: getNarrationValue(state),
    billItemsPrice: getVoucherModuleResultState(vouchers).billItemsPrice,
    billDiscountAmount,
    billAmountBeforeOtherCharges,
    additionalDiscount: vouchers.purchase.additionalDiscount,
    otherChargesTotal: getVoucherModuleResultState(vouchers).otherChargesTotal,
    otherChargesAfterTaxTotal,
    billAmountBeforeTax,
    lineAmountSum,
    lineItemQtySum: getTotalQty(state),
    billTaxAmount: getVoucherModuleResultState(vouchers).billTaxAmount,
    adjustments: adjustments,
    billFinalAmount,
    documents: getdocumentSchema(documents),
    hasExclusiveDiscount: exclusiveDiscountFlag,
    exclusiveDiscountSummary: getVoucherModuleResultState(vouchers).exclusiveDiscountList,
    salesDiscounts: formattedSalesDiscounts,
    couponDiscount,
    discountBreakup: getDiscountBreakup(state),
    recordRpPayload,
    recordRpVoucher,
    billAmountBeforeOtherChargesAfterTax,
    purchaseDiscounts: formattedPurchaseDiscounts
  };

  if (pickUpLocation && pickUpLocation.state) {
    voucherObject.pickUpLocation = pickUpLocation;
  }

  if (
    (type === VOUCHER_TYPE_CREDIT_NOTE || type === VOUCHER_TYPE_DEBIT_NOTE) &&
    subType === ADJUSTMENT_ON_ITEM_PRICE
  ) {
    voucherObject.subType = ADJUSTMENT_ON_ITEM_PRICE;
  }

  // calculate discount
  const newItemList = calculateLineItemsDiscountBreakupAndEffectivePrice(
    voucherObject.itemList,
    voucherObject.lineAmountSum,
    voucherObject.additionalDiscount,
    voucherObject.hasExclusiveDiscount,
    salesTypeFlag(voucherObject.type)
      ? voucherObject.salesDiscounts
      : voucherObject.purchaseDiscounts,
    voucherObject.couponDiscount,
    voucherObject.discountBreakup,
    currencyDecimals
  );

  voucherObject.itemList = newItemList;

  return voucherObject;
};

const generateExpensePayload = state => {
  const {
    vouchers: {
      selectedPartyAccount,
      _roundOffOffset,
      _selectedBranch,
      _selectedVoucherAccount,
      _issueDateObj,
      _voucherModuleResults,
      _vouchersById: { expense },
      documents
    },
    vouchers,
    currentCompany: { id: iCompanyId }
    // narration,

    // mode,
  } = state;

  let voucherPayload = {};
  voucherPayload.voucherNo = expense.voucherNo;
  voucherPayload.party = getPartyObj(selectedPartyAccount);
  voucherPayload.supplierInvoiceNo = vouchers.supplierInvoiceNo;
  voucherPayload.supplierInvoiceDate = vouchers.supplierInvoiceDate;
  voucherPayload.dueDate = getFormattedDateIso(vouchers.dueDate);
  voucherPayload.narration = getNarrationValue(state);
  voucherPayload.adjustments = _roundOffOffset;
  voucherPayload.iBranchId = _selectedBranch ? _selectedBranch.id : null;
  voucherPayload.refAccountId = _selectedVoucherAccount.id;
  voucherPayload.refAccountName = _selectedVoucherAccount.name;
  voucherPayload.refAccountGroup = _selectedVoucherAccount.accountGroupName;
  voucherPayload.refPath = _selectedVoucherAccount.path;
  voucherPayload.issueDate = getFormattedDateIso(_issueDateObj);
  voucherPayload.tax = _voucherModuleResults.tax;
  voucherPayload.dueAmount = _voucherModuleResults.billFinalAmount;
  voucherPayload.billFinalAmount = _voucherModuleResults.billFinalAmount;
  voucherPayload.billTaxAmount = _voucherModuleResults.billTaxAmount;
  voucherPayload.iCompanyId = iCompanyId;
  voucherPayload.documents = getdocumentSchema(documents);
  voucherPayload.lineAmountSum = getVoucherModuleResultState(vouchers).lineAmountSum;

  return voucherPayload;
};
/**
 * manipulating itemlist for blank itemiD and unwanted fields
 */
const stripLineItemsForUnwantedFields = function(lineItems) {
  return map(lineItems, lineItem => {
    if (!lineItem.itemId) {
      delete lineItem.itemId;
      delete lineItem.itemName;
      delete lineItem.itemSkuBarCode;
      delete lineItem.hsn;
      delete lineItem.unitPrice;
      delete lineItem.qty;
      delete lineItem.unit;
      delete lineItem.qtySellPrice;
      delete lineItem.discountValue;
      delete lineItem.discountUnit;
      delete lineItem.discountAmount;
      delete lineItem.taxPercentage;
      delete lineItem.taxAmount;
      delete lineItem.lineAmount;
      delete lineItem.lineDifference;
      delete lineItem.showItemPicker;
      delete lineItem.itemGroupId;
    }
    delete lineItem.loadItemQty;
    if (lineItem.discountIconRupees !== undefined) {
      delete lineItem.discountIconRupees;
    }
    // if (lineItem.hasOwnProperty('unitPurchasePrice')) {
    //   delete lineItem.unitPurchasePrice;
    // }
    // if (lineItem.hasOwnProperty('unitSellWholeSalePrice')) {
    //   delete lineItem.unitSellWholeSalePrice;
    // }
    if (lineItem.hasOwnProperty('showItemPicker')) {
      delete lineItem.showItemPicker;
    }
    return lineItem;
  });
};

const stripOtherCahrgesForBlankId = function(otherCharges) {
  return map(otherCharges, otherCharge => {
    if (!otherCharge.refAccountId) {
      delete otherCharge.refAccountId;
      delete otherCharge.name;
      delete otherCharge.description;
      delete otherCharge.debitAmount;
      delete otherCharge.creditAmount;
      delete otherCharge.amount;
      delete otherCharge.accountGroupName;
    }
    return otherCharge;
  });
};
// const genrateCreditDebit = state => {
//   const {
//     vouchers,
//     vouchers: { _issueDateObj, _selectedVoucherByNo, _selectedBranch, documents },
//     table: { sorting }
//   } = state;
//   const adjustments = vouchers._roundOffOffset;
//   const branchId = _selectedBranch ? _selectedBranch.id : null;
//   // manipulating itemlist for blank itemiD and unwanted fields
//   const cloneItemList = cloneDeep(vouchers._lineItems);
//   const manipulateLineItems = stripLineItemsForUnwantedFields(cloneItemList);
//   let lineItemsArray = manipulateLineItems.filter(value => Object.keys(value).length !== 0);
//   lineItemsArray = sortLineItemByUserPreference(lineItemsArray, sorting);
//   // manipulating tax for blank taxPercentage or zero percentage
//   const manipulateTax = cloneDeep(getVoucherModuleResultState(vouchers).tax);
//   const taxArray =
//     manipulateTax && manipulateTax.length > 0
//       ? manipulateTax.filter(value => Object.keys(value).length !== 0)
//       : [];

//   if (_selectedVoucherByNo && Object.keys(_selectedVoucherByNo).length > 0) {
//     return {
//       // voucherNo: '',
//       iBranchId: branchId,
//       refAccountId: vouchers._selectedVoucherAccount.id,
//       refAccountName: vouchers._selectedVoucherAccount.name,
//       refAccountGroup: vouchers._selectedVoucherAccount.accountGroupName,
//       refPath: vouchers._selectedVoucherAccount.path,
//       issueDate: getFormattedDateIso(_issueDateObj),
//       narration: getNarrationValue(state),
//       notifyParty: false,
//       internalNotes: [],
//       party: _selectedVoucherByNo.party,
//       itemList: map(lineItemsArray, (lineItem, i) => {
//         return {
//           ...lineItem
//         };
//       }),
//       supplierInvoiceNo: vouchers.supplierInvoiceNo,
//       supplierInvoiceDate: vouchers.supplierInvoiceDate,
//       verifiedBy: [],
//       voucherRefNo: _selectedVoucherByNo.voucherNo,
//       refVoucherId: _selectedVoucherByNo.id,
//       billItemsPrice: getVoucherModuleResultState(vouchers).billItemsPrice,
//       billDiscountAmount: getVoucherModuleResultState(vouchers).billDiscountAmount,
//       billTaxAmount: getVoucherModuleResultState(vouchers).billTaxAmount,
//       billFinalAmount: getVoucherModuleResultState(vouchers).billFinalAmount,
//       dueAmount: getVoucherModuleResultState(vouchers).dueAmount,
//       lineAmountSum: getVoucherModuleResultState(vouchers).lineAmountSum,
//       lineItemQtySum: getTotalQty(state),
//       documents: getdocumentSchema(documents),
//       adjustments: adjustments,
//       tax: taxArray
//     };
//   }
// };
const getbranchNamesArray = ({ vouchers: { _branches } }) => _branches.map(branch => branch.name);
const getBranchesArray = ({ vouchers: { _branches } }) => _branches;
const getHideShippingDetails = ({
  vouchers: {
    app: { hideShippingDetails }
  }
}) => hideShippingDetails;
const getAccountsDetails = ({ vouchers: { voucherAccountList } }) => {
  return voucherAccountList.map(account => account.name);
};
const getVoucherDetailsAccounts = ({ vouchers: { _voucherDetailsAccounts } }) =>
  _voucherDetailsAccounts;
const getBranchSearchText = ({ _branchSearchText }) => _branchSearchText;
const getSavedVouchersSuccess = ({ vouchers: { savedVouchersSuccess } }) => savedVouchersSuccess;
const getUpdatedVouchersSuccess = ({ vouchers: { updatedVouchersSuccess } }) =>
  updatedVouchersSuccess;
const getShowLinkForVoucherDetails = ({
  vouchers: {
    app: { voucherDetailsLink }
  }
}) => voucherDetailsLink;
const getshowAlertFlagStatus = ({
  vouchers: {
    app: { showAlertFlag }
  }
}) => showAlertFlag;
const getAlertDialogMessage = ({
  vouchers: {
    app: { alertMessage }
  }
}) => alertMessage;
const getShowItemPicker = () => false;
const getLineItemSearchText = ({
  vouchers: {
    app: {
      lineItem: { lineItemSearchText }
    }
  }
}) => lineItemSearchText;
const getSelectedCreditNoteVoucher = ({
  vouchers: {
    _vouchersById: { credit_note }
  }
}) => credit_note;
const getSeletedDebitNoteVoucher = ({
  vouchers: {
    _vouchersById: { debit_note }
  }
}) => debit_note;
const getVoucherTypes = ({ vouchers: { voucherTypes } }) => voucherTypes;
const getAccountTypes = ({ vouchers: { voucherAccounts } }, voucherType) =>
  voucherAccounts[voucherType];
const getCalcStatus = ({ vouchers: { calcStatus } }) => calcStatus[GET_CALCULATED_ITEMS];
const getCalcEnabledStatus = ({
  vouchers: {
    app: { calcEnabled }
  }
}) => calcEnabled;
const getVoucherCountByType = ({ vouchers: { voucherList } }, voucherType) =>
  find(voucherList, listItem => listItem._id === voucherType);

const getrecordReceiptModeObj = ({ vouchers: { recordReceiptMode } }) => recordReceiptMode;

export {
  lastVisitedVoucher,
  vouchersUpdateState,
  getAccountsName,
  getHideShippingDetails,
  getShowLink,
  getUnpaidVouchersList,
  getAllVouchersList,
  getVoucherDetails,
  getSavedVouchersSuccess,
  getUpdatedVouchersSuccess,
  getVoucherDetailsAccounts,
  getSelectedVoucherDetails,
  getVoucherDurationType,
  voucherTypesUpdateState,
  getBranchSearchText,
  getbranchNamesArray,
  selectedVoucher,
  getShowCardForVoucherDetails,
  getselectedBusinessAccount,
  getSearchText,
  getSummaryBlockData,
  getShippingAddress,
  getShowTransportCard,
  getTempItems,
  getVoucherType,
  vouchers,
  getParty,
  getIssueDate,
  getStartDate,
  getSelectedAccountDetails,
  getAccountsDetails,
  getVoucherByNumber,
  getNarrationValue,
  getCurrentBalance,
  getTotalQty,
  getSelectedPurchaseVoucher,
  getSelectedSalesVoucher,
  getSelectedSchallanVoucher,
  getSelectedSestimationVoucher,
  getSelectedPestimationVoucher,
  getSelectedCreditNote,
  getSelectedDebitNote,
  getRoundOffValues,
  getTransportDetails,
  getToogleBusinessCard,
  getlineAmountSum,
  getTaxAnalysisData,
  getOtherChargesAfterTax,
  genratePurchaseSalesPayload,
  getopenAddDrawer,
  getotherChargesTaxBreakup,
  getbusinessAccountsSuggestions,
  getShowLinkForVoucherDetails,
  getshowAlertFlagStatus,
  getAlertDialogMessage,
  getShowItemPicker,
  getLineItemSearchText,
  getTransportationLinkStatus,
  getSelectedCreditNoteVoucher,
  getSeletedDebitNoteVoucher,
  generateChallanLinkedSalesPurchasePayload,
  generatePayloadForLinkedEstimation,
  generateChallanPayload,
  addEditDrawerFlags,
  getChallanListForSPVoucher,
  getSelectedPchallanVoucher,
  getVoucherTypes,
  getSelectedExpenseVoucher,
  generateExpensePayload,
  getAccountTypes,
  getCalcStatus,
  getCalcEnabledStatus,
  getDiscountBreakup,
  getTotalQtyFromSelectedVoucher,
  getListOfCurrentVoucher,
  getVoucherCountByType,
  getSelectedStockVoucher,
  getListOfStockVoucher,
  getCurrentVoucherListPageNo,
  getSalesPersonDetails,
  getSalesPersonLinkStatus,
  getBranchesArray,
  itemAvaliableQtyList,
  getrecordReceiptModeObj,
  getPickUpLocationLinkStatus,
  getTotalBeforeOtherCharges
};
